import React, { useEffect } from "react";
import "./volcard.css";

import arrival from "../../assets/images/images/arrival.png"
import destination from "../../assets/images/images/destination.png"
import depart from "../../assets/images/images/depart.png"

export default function Volcard({
  departureDate,
  arrivalDate,
  fullwidth,
  voya,
}) {
  useEffect(() => {
   
  }, []);

  // Format the date to "Day MonthName Year"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);
  };

  return (
    <div className="Volcard" style={fullwidth ? { width: "100%" } : {}}>
      <div className="vol_left_part">
        <p className="name">{voya.airline}</p>
        <p className="type">Economique</p>
      </div>

      <div className="vol_center_part">
        <p className="departure">
          <span><img src={depart}/> Depart Date :{formatDate(departureDate)}</span> 
        </p>
        <p className="arrival">
          <span><img src={arrival}/>Arrivale Date :{formatDate(arrivalDate)}</span> 
        </p>
        <p className="arrival">
          <span><img src={destination}/>Destination : {voya.cite}</span> 
        </p>
      </div>
    </div>
  );
}
