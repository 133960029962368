import React from "react";
import "./dates.css";

export default function Dates({ availableDates }) {
  return (
    <div className="dates">
      <h2>Dates disponibles</h2>
      <ul className="dates-list">
        {availableDates.map((dateRange, index) => (
          <li key={index} className="date-item">
            {new Date(dateRange.start).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            - {" "}
            {new Date(dateRange.end).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </li>
        ))}
      </ul>
    </div>
  );
}
