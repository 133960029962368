import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import Footer from "../../components/Footer/Footer";
import API from "../../API";
import { hotelroom } from "../../assets/images/images";
import "./checkouthotel.css";
import Modal from "react-modal";

export default function Checkouthotel() {

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const openPrivacyModal = (e) => {
    e.preventDefault();
    setIsPrivacyModalOpen(true);
  };

  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
  };

  const handlePrivacyAccept = () => {
    setTermsAccepted(true);
    closePrivacyModal();
  };




  const handleRoomSelect = (detail) => {
    setSelectedRoom(detail);
    
    // Reset occupancy to minimum values when room changes
    const initialAdults = 1;
    const initialChildren = 0;
    
    setFormData(prev => ({
      ...prev,
      roomId: detail.room_id,
      numAdults: initialAdults,
      numChildren: initialChildren
    }));
    
    // Calculate initial price with minimum occupancy
    const initialPrice = calculatePricePerNight(detail, initialAdults, initialChildren);
    setPricePerNight(initialPrice);
    
    // Update grand total if dates are selected
    if (checkinDate && checkoutDate) {
      const nights = calculateNights(checkinDate, checkoutDate);
      setGrandTotal(initialPrice * nights);
    }
  };


  const calculatePricePerNight = (detail, numAdults, numChildren) => {
    let basePrice = 0;
    const extraAdultPrice = parseInt(detail.price_extra || "0", 10);
    
    // Handle base pricing for 1-5 adults
    if (numAdults === 1) {
      basePrice = parseInt(detail.price_1 || "0", 10);
    } else if (numAdults === 2) {
      basePrice = parseInt(detail.price_2 || detail.price_1 || "0", 10);
    } else if (numAdults === 3) {
      basePrice = parseInt(detail.price_3 || detail.price_2 || detail.price_1 || "0", 10);
    } else if (numAdults === 4) {
      basePrice = parseInt(detail.price_4 || detail.price_3 || detail.price_2 || detail.price_1 || "0", 10);
    } else if (numAdults === 5) {
      basePrice = parseInt(detail.price_5 || detail.price_4 || detail.price_3 || detail.price_2 || detail.price_1 || "0", 10);
    } else if (numAdults > 5) {
      // For more than 5 adults:
      // 1. Start with price_5 as the base
      basePrice = parseInt(detail.price_5 || detail.price_4 || detail.price_3 || detail.price_2 || detail.price_1 || "0", 10);
      // 2. Add extra adult fee for each adult beyond 5
      const extraAdults = numAdults - 5;
      basePrice += extraAdults * extraAdultPrice;
    }
  
    // Add the price for children
    const childPricePerNight = parseInt(detail.price_child || "0", 10);
    const totalChildPrice = childPricePerNight * numChildren;
  
    return basePrice + totalChildPrice;
  };
  
  
  






  const PrivacyTermsModal = ({ isOpen, onClose, onAccept }) => {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "600px",
            maxHeight: "80vh",
            overflow: "auto",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
          },
        }}
        contentLabel="Privacy and Terms Policy"
      >
        <div className="modal-header">
          <h2 className="text-xl font-bold mb-4">Politique de Confidentialité et Conditions d'Utilisation</h2>
        </div>
        <div className="modal-content">
          <h3 className="font-semibold mb-2">1. Collecte des Informations Personnelles</h3>
          <p className="mb-4">
            Nous collectons des informations personnelles que vous nous fournissez directement, telles que votre nom,
            adresse e-mail, et informations de passeport lors de votre demande de visa.
          </p>
          <h3 className="font-semibold mb-2">2. Utilisation des Informations</h3>
          <p className="mb-4">
            Vos informations sont utilisées uniquement dans le cadre du traitement de votre demande de visa et ne seront
            jamais partagées avec des tiers sans votre consentement explicite.
          </p>
          <h3 className="font-semibold mb-2">3. Protection des Données</h3>
          <p className="mb-4">
            Nous mettons en œuvre des mesures de sécurité avancées pour protéger vos informations personnelles contre
            tout accès non autorisé, modification ou divulgation.
          </p>
          <h3 className="font-semibold mb-2">4. Délai de Traitement</h3>
          <p className="mb-4">
            Tout délai de traitement est susceptible de varier en fonction de la complexité de votre demande et des
            vérifications nécessaires.
          </p>
          <h3 className="font-semibold mb-2">5. Erreurs et Annulations</h3>
          <p className="mb-4">
            Toute erreur dans les informations de visa doit être immédiatement signalée et peut entraîner l'annulation
            de la demande.
          </p>
        </div>
        <div className="modal-footer mt-4 flex justify-end">
          <button
            onClick={onAccept}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            style={{
              background: "orange",
              padding: "15px",
              fontFamily: "sans-serif",
              fontSize: "15px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            J'ai lu les conditions
          </button>
        </div>
      </Modal>
    );
  };














  const location = useLocation();
  const navigate = useNavigate();
  const rate = location.state?.rate;
  

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("protoken");

  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [totalNights, setTotalNights] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [pricePerNight, setPricePerNight] = useState(0);


  const [formData, setFormData] = useState({
    name: "",
    surnom: "",
    email: "",
    address: "",
    zipcode: "",
    phone: "",
    propertyId: rate?.property_id,
    userId: userId,
    roomId: "", // Add roomId to track selected room
    numAdults: 1,
    numChildren: 0,
  });

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});

  const notifySuccess = () => 
    toast.success("Reservation request successful!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const notifyLoginRequired = () => 
    toast.error("You need to login first!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const notifyError = (message) => 
    toast.error(`Error: ${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  // Validation functions
  const isValidName = (name) => name.trim().length > 1;
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPhone = (phone) => /^(05|06|07)\d{8}$/.test(phone);

  const calculateNights = (start, end) => {
    if (!start || !end) return 0;
    const diffTime = end - start;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const calculateGrandTotal = (nights) => {
    if (!rate || !rate.details || nights === 0) return 0;
    return rate.details.reduce((total, detail) => {
      const pricePerNight = parseInt(detail.price_1 || "0", 10);
      return total + pricePerNight * nights;
    }, 0);
  };

  useEffect(() => {
    if (checkinDate && checkoutDate && selectedRoom) {
      const nights = calculateNights(checkinDate, checkoutDate);
      setTotalNights(nights);
      setGrandTotal(pricePerNight * nights);
    }
  }, [checkinDate, checkoutDate, selectedRoom, pricePerNight]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Determine numValue based on selected option
    let numValue = value;
  
    if (name === 'numAdults' || name === 'numChildren') {
      numValue = parseInt(value, 10) || 0; // Default to 0 if invalid number
    }
  
    setFormData((prev) => ({
      ...prev,
      [name]: numValue,
    }));
  
    // Recalculate price only if it's occupancy related changes and we have a selected room
    if ((name === 'numAdults' || name === 'numChildren') && selectedRoom) {
      const newAdults = name === 'numAdults' ? numValue : formData.numAdults;
      const newChildren = name === 'numChildren' ? numValue : formData.numChildren;
      
      // Calculate new price based on updated occupancy
      const newPricePerNight = calculatePricePerNight(selectedRoom, newAdults, newChildren);
      setPricePerNight(newPricePerNight);
      
      // Update grand total if dates are selected
      if (checkinDate && checkoutDate) {
        const nights = calculateNights(checkinDate, checkoutDate);
        setGrandTotal(newPricePerNight * nights);
      }
    }
  };
  
  
  

  // Validate form before submission
  const validateForm = () => {
    const newErrors = {};

    if (!isValidName(formData.name))
      newErrors.name = "Please enter a valid name";

    if (!isValidName(formData.surnom))
      newErrors.surnom = "Please enter a valid surname";

    if (!isValidEmail(formData.email))
      newErrors.email = "Please enter a valid email address";

    if (formData.address.trim().length < 5)
      newErrors.address = "Please enter a valid address";

    if (!isValidPhone(formData.phone))
      newErrors.phone = "Phone number must start with 05, 06, or 07 and be 10 digits long";

    if (!checkinDate)
      newErrors.checkinDate = "Please select a valid check-in date";

    if (!checkoutDate)
      newErrors.checkoutDate = "Please select a valid check-out date";

    if (checkinDate && checkoutDate && checkoutDate <= checkinDate)
      newErrors.checkoutDate = "Check-out date must be after check-in date";

    if (!termsAccepted)
      newErrors.terms = "You must accept the Privacy & Terms Policy";

    
    if (!selectedRoom) {
      newErrors.room = "Please select a room";
    } else {
      if (formData.numAdults < 1 || formData.numAdults > selectedRoom.room.max_adults) {
        newErrors.numAdults = `Number of adults must be between 1 and ${selectedRoom.room.max_adults}`;
      }
      if (formData.numChildren > selectedRoom.room.max_children) {
        newErrors.numChildren = `Number of children cannot exceed ${selectedRoom.room.max_children}`;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePayment = async () => {
    if (!token) {
      notifyLoginRequired();
      
      return;
    }

    if (validateForm()) {
      const { roomId, numAdults, numChildren, ...submissionData } = {
        ...formData,
        checkinDate: checkinDate?.toISOString().split("T")[0],
        checkoutDate: checkoutDate?.toISOString().split("T")[0],
        totalNights,
        totalPrice: grandTotal,
      };

      try {
        console.log(submissionData)
        const response = await API.post("/hotelreservations", submissionData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        
        notifySuccess();
        setTimeout(() => {
          navigate("/"); 
        }, 3000); 
      } catch (error) {
        console.error("Error submitting data:", error);
        notifyError(error.response?.data?.message || "Unexpected error occurred.");
      }
    }
  };

  if (!rate) {
    return (
      <div>
        <Topbar />
        <Navbar />
        <div className="main">
          <p className="big_title">Checkout</p>
          <div className="container">
            <p className="error">Rate information not available.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  const formatPrice = (price) =>
    price ? parseInt(price, 10).toLocaleString("fr-FR") + " DZD" : "N/A";

  return (
    <div className="Checkout_hotel">
      <Topbar />
      <Navbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"  // You can change to "dark" if preferred
      />
      <div className="main">
        <p className="big_title">Checkout</p>
        <div className="container">
          <div className="form">
            <form>
              <p className="title">Information général</p>
              <div className="two_inputs">
                <label>
                  <p className="input_label">Nom</p>
                  <input
                    type="text"
                    placeholder="Mohamed"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </label>
                <label>
                  <p className="input_label">Surnom</p>
                  <input
                    type="text"
                    placeholder="El Amine"
                    name="surnom"
                    value={formData.surnom}
                    onChange={handleInputChange}
                  />
                  {errors.surnom && <p className="error">{errors.surnom}</p>}
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Email Address</p>
                  <input
                    type="email"
                    placeholder="mohamed.elamine@gmail.com"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Street Address</p>
                  <input
                    type="text"
                    placeholder="Batna street 300"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                  {errors.address && <p className="error">{errors.address}</p>}
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Select Room</p>
                  <select 
                    onChange={(e) => {
                      const detail = rate.details.find(d => d.room_id === e.target.value);
                      handleRoomSelect(detail);
                    }}
                    value={selectedRoom?.room_id || ""}
                    className="room-select"
                  >
                    <option value="" disabled>Select a room</option>
                    {rate.details.map((detail) => (
                      <option key={detail.room_id} value={detail.room_id}>
                        {detail.room.code} - {detail.room.description} ({formatPrice(detail.price_1)}/night)
                      </option>
                    ))}
                  </select>
                  {errors.room && <p className="error">{errors.room}</p>}
                </label>
              </div>
              {selectedRoom && (
                <div className="two_inputs">
  <label>
    <p className="input_label">Number of Adults</p>
    <select
      name="numAdults"
      value={formData.numAdults}
      onChange={handleInputChange}
      className="room-select"
      style={{minWidth:'150px'}}
    >
            {[...Array(selectedRoom.room.max_adults)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1} {i === 0 ? 'Adult' : 'Adults'}
              </option>
            ))}
    </select>
    {errors.numAdults && <p className="error">{errors.numAdults}</p>}
  </label>

                  <label>
                    <p className="input_label">Number of Children</p>
                    <select
                      name="numChildren"
                      value={formData.numChildren}
                      onChange={handleInputChange}
                      className="room-select"
                      style={{minWidth:'150px'}}
                    >
            {[...Array(selectedRoom.room.max_children + 1)].map((_, i) => (
              <option key={i} value={i}>
                {i} {i === 1 ? 'Child' : 'Children'}
              </option>
            ))}
                    </select>
                    {errors.numChildren && <p className="error">{errors.numChildren}</p>}
                  </label>
                </div>
              )}

              <div className="two_inputs">
                <label>
                  <p className="input_label" style={{top:"-35px"}}>Check-in Date</p>
                  <DatePicker
                    selected={checkinDate}
                    onChange={(date) => setCheckinDate(date)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select check-in date"
                  />
                  {errors.checkinDate && <p className="error">{errors.checkinDate}</p>}
                </label>
                <label>
                  <p className="input_label" style={{top:"-35px"}}>Check-out Date</p>
                  <DatePicker
                    selected={checkoutDate}
                    onChange={(date) => setCheckoutDate(date)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select check-out date"
                    minDate={checkinDate}
                  />
                  {errors.checkoutDate && <p className="error">{errors.checkoutDate}</p>}
                </label>
              </div>
              <div className="two_inputs">
                <label>
                  <p className="input_label">Zip/Postal Code</p>
                  <input
                    type="text"
                    placeholder="5005"
                    name="zipcode"
                    value={formData.zipcode}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  <p className="input_label">Phone</p>
                  <input
                    type="tel"
                    placeholder="0512345678"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </label>
              </div>
              <div className="check">
                <label>
                  <input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />{" "}
                  <p className="text">
                    Please check to acknowledge our{" "}
                    <a href="/#" onClick={openPrivacyModal}>Privacy & Terms Policy</a>
                  </p>
                </label>
                {errors.terms && <p className="error">{errors.terms}</p>}
                <PrivacyTermsModal
        isOpen={isPrivacyModalOpen}
        onClose={closePrivacyModal}
        onAccept={handlePrivacyAccept}
      />
              </div>
            </form>
          </div>
          <div className="facture">
            <img className="image" src={hotelroom} alt="hotel room" />
            <div className="content">
              <p className="title">Récapitulatif de facturation</p>
              {selectedRoom && (
                <>
                  <div className="el">
                    <p className="info">
                      {selectedRoom.room.code} - {selectedRoom.room.description}
                    </p>
                    <p className="price">
                      {formatPrice(pricePerNight)} / nuit
                    </p>
                  </div>
                  <div className="el">
                    <p className="info">Occupancy</p>
                    <p className="price">
                      {formData.numAdults} Adults, {formData.numChildren} Children
                    </p>
                  </div>
                </>
              )}
              <div className="line"></div>
              <div className="el">
                <p className="total">Total Nights</p>
                <p className="total_price">{totalNights} Nights</p>
              </div>
              <div className="el">
                <p className="total">Grand Total</p>
                <p className="total_price">{formatPrice(grandTotal)}</p>
              </div>
              <div className="el caso">
                <button className="payement1" onClick={handlePayment}>
                  <p>Paiement dans bureau</p>
                </button>
                <button 
          className="payement2" 
          onClick={() => window.history.back()}
          
     
        >
          <p>Retour</p>
        </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}
