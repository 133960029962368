import React from "react";
import { perssonesIcon } from "../../assets/images/icons";
import { API_URL_IMAGE, API_URL_IMAGE2 } from "../../constants";
import "./hotelroom.css";
import { useNavigate } from "react-router-dom";

export default function Hotelroom({ rate }) {

  const navigate = useNavigate(); // Initialize navigation hook

  const roomDetails = rate?.details?.[0];
  const room = roomDetails?.room;
  const roomImage = room?.pictures?.[0];
 

  return (
    <div className="Hotelroom">
      <div className="top_image_container">
        <img 
          className="top_image" 
          src={`${API_URL_IMAGE2[process.env.NODE_ENV]}media/rooms/${roomImage?.filename || ''}`} 
          alt="hotel room" 
        />
      
      </div>
      
      <div className="bottom_container">
        <div className="info">
          <img className="icon" src={perssonesIcon} alt="persons" />
          <p className="el">
            {`${room?.max_adults || 0} Adultes, ${room?.max_children || 0} Enfants`}
          </p>
        </div>
        
        <p className="desc">
          {room?.description || 'No room description available'}
        </p>
        
        <div className="reservation">
          <p className="price">{`${roomDetails?.price_1 || '0'} DZD`}</p>
          <button
            onClick={() =>
              navigate("/hcheckout", { state: { rate } }) // Pass the rate to hcheckout
            }
          >
            <p>Reserver</p>
          </button>
        </div>
      </div>
    </div>
  );
}