import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./checkoutvol.css";
import Volcard from "../../components/Volcard/Volcard";
import API from "../../API";
import { useLocation } from "react-router-dom";
import { hotelroom, payementqbIcon } from "../../assets/images/images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const nationalities = [
  { value: "algerian", label: "Algerian" },
  { value: "afghan", label: "Afghan" },
  { value: "albanian", label: "Albanian" },

  { value: "andorran", label: "Andorran" },
  { value: "angolan", label: "Angolan" },
  { value: "argentine", label: "Argentine" },
  { value: "armenian", label: "Armenian" },
  { value: "australian", label: "Australian" },
  { value: "austrian", label: "Austrian" },
  { value: "azerbaijani", label: "Azerbaijani" },
  { value: "bahamian", label: "Bahamian" },
  { value: "bahraini", label: "Bahraini" },
  { value: "bangladeshi", label: "Bangladeshi" },
  { value: "barbadian", label: "Barbadian" },
  { value: "belarusian", label: "Belarusian" },
  { value: "belgian", label: "Belgian" },
  { value: "belizean", label: "Belizean" },
  { value: "beninese", label: "Beninese" },
  { value: "bhutanese", label: "Bhutanese" },
  { value: "bolivian", label: "Bolivian" },
  { value: "bosnian", label: "Bosnian" },
  { value: "botswanan", label: "Botswanan" },
  { value: "brazilian", label: "Brazilian" },
  { value: "bruneian", label: "Bruneian" },
  { value: "bulgarian", label: "Bulgarian" },
  { value: "burkinabe", label: "Burkinabé" },
  { value: "burundian", label: "Burundian" },
  { value: "cambodian", label: "Cambodian" },
  { value: "cameroonian", label: "Cameroonian" },
  { value: "canadian", label: "Canadian" },
  { value: "cape_verdean", label: "Cape Verdean" },
  { value: "chadian", label: "Chadian" },
  { value: "chilean", label: "Chilean" },
  { value: "chinese", label: "Chinese" },
  { value: "colombian", label: "Colombian" },
  { value: "comoran", label: "Comoran" },
  { value: "congolese", label: "Congolese" },
  { value: "costa_rican", label: "Costa Rican" },
  { value: "croatian", label: "Croatian" },
  { value: "cuban", label: "Cuban" },
  { value: "cypriot", label: "Cypriot" },
  { value: "czech", label: "Czech" },
  { value: "danish", label: "Danish" },
  { value: "djiboutian", label: "Djiboutian" },
  { value: "dominican", label: "Dominican" },
  { value: "ecuadorian", label: "Ecuadorian" },
  { value: "egyptian", label: "Egyptian" },
  { value: "salvadoran", label: "Salvadoran" },
  { value: "equatorial_guinean", label: "Equatorial Guinean" },
  { value: "eritrean", label: "Eritrean" },
  { value: "estonian", label: "Estonian" },
  { value: "eswatini", label: "Eswatini" },
  { value: "ethiopian", label: "Ethiopian" },
  { value: "fijian", label: "Fijian" },
  { value: "finnish", label: "Finnish" },
  { value: "french", label: "French" },
  { value: "gabonese", label: "Gabonese" },
  { value: "gambian", label: "Gambian" },
  { value: "georgian", label: "Georgian" },
  { value: "german", label: "German" },
  { value: "ghanaian", label: "Ghanaian" },
  { value: "greek", label: "Greek" },
  { value: "grenadian", label: "Grenadian" },
  { value: "guatemalan", label: "Guatemalan" },
  { value: "guinean", label: "Guinean" },
  { value: "guyanese", label: "Guyanese" },
  { value: "haitian", label: "Haitian" },
  { value: "honduran", label: "Honduran" },
  { value: "hungarian", label: "Hungarian" },
  { value: "icelandic", label: "Icelandic" },
  { value: "indian", label: "Indian" },
  { value: "indonesian", label: "Indonesian" },
  { value: "iranian", label: "Iranian" },
  { value: "iraqi", label: "Iraqi" },
  { value: "irish", label: "Irish" },
  { value: "Palestinian", label: "Palestinian" },
  { value: "italian", label: "Italian" },
  { value: "ivorian", label: "Ivorian" },
  { value: "jamaican", label: "Jamaican" },
  { value: "japanese", label: "Japanese" },
  { value: "jordanian", label: "Jordanian" },
  { value: "kazakh", label: "Kazakh" },
  { value: "kenyan", label: "Kenyan" },
  { value: "kiribati", label: "Kiribati" },
  { value: "kuwaiti", label: "Kuwaiti" },
  { value: "kyrgyz", label: "Kyrgyz" },
  { value: "laotian", label: "Laotian" },
  { value: "latvian", label: "Latvian" },
  { value: "lebanese", label: "Lebanese" },
  { value: "lesotho", label: "Lesotho" },
  { value: "liberian", label: "Liberian" },
  { value: "libyan", label: "Libyan" },
  { value: "liechtensteiner", label: "Liechtensteiner" },
  { value: "lithuanian", label: "Lithuanian" },
  { value: "luxembourgish", label: "Luxembourgish" },
  { value: "madagascan", label: "Madagascan" },
  { value: "malawian", label: "Malawian" },
  { value: "malaysian", label: "Malaysian" },
  { value: "maldivian", label: "Maldivian" },
  { value: "malian", label: "Malian" },
  { value: "maltese", label: "Maltese" },
  { value: "mauritanian", label: "Mauritanian" },
  { value: "mauritian", label: "Mauritian" },
  { value: "mexican", label: "Mexican" },
  { value: "monacan", label: "Monacan" },
  { value: "mongolian", label: "Mongolian" },
  { value: "montenegrin", label: "Montenegrin" },
  { value: "moroccan", label: "Moroccan" },
  { value: "mozambican", label: "Mozambican" },
  { value: "myanmarese", label: "Myanmarese" },
  { value: "namibian", label: "Namibian" },
  { value: "nepalese", label: "Nepalese" },
  { value: "dutch", label: "Dutch" },
  { value: "new_zealander", label: "New Zealander" },
  { value: "nigerian", label: "Nigerian" },
  { value: "norwegian", label: "Norwegian" },
  { value: "omani", label: "Omani" },
  { value: "pakistani", label: "Pakistani" },
  { value: "panamanian", label: "Panamanian" },
  { value: "peruvian", label: "Peruvian" },
  { value: "philippine", label: "Philippine" },
  { value: "polish", label: "Polish" },
  { value: "portuguese", label: "Portuguese" },
  { value: "qatari", label: "Qatari" },
  { value: "romanian", label: "Romanian" },
  { value: "russian", label: "Russian" },
  { value: "rwandan", label: "Rwandan" },
  { value: "saudi", label: "Saudi" },
  { value: "senegalese", label: "Senegalese" },
  { value: "serbian", label: "Serbian" },
  { value: "seychellois", label: "Seychellois" },
  { value: "singaporean", label: "Singaporean" },
  { value: "slovak", label: "Slovak" },
  { value: "slovenian", label: "Slovenian" },
  { value: "somali", label: "Somali" },
  { value: "south_african", label: "South African" },
  { value: "south_korean", label: "South Korean" },
  { value: "spanish", label: "Spanish" },
  { value: "sri_lankan", label: "Sri Lankan" },
  { value: "sudanese", label: "Sudanese" },
  { value: "swedish", label: "Swedish" },
  { value: "swiss", label: "Swiss" },
  { value: "syrian", label: "Syrian" },
  { value: "taiwanese", label: "Taiwanese" },
  { value: "tajik", label: "Tajik" },
  { value: "tanzanian", label: "Tanzanian" },
  { value: "thai", label: "Thai" },
  { value: "togolese", label: "Togolese" },
  { value: "tunisian", label: "Tunisian" },
  { value: "turkish", label: "Turkish" },
  { value: "ugandan", label: "Ugandan" },
  { value: "ukrainian", label: "Ukrainian" },
  { value: "emirati", label: "Emirati" },
  { value: "british", label: "British" },
  { value: "american", label: "American" },
  { value: "uruguayan", label: "Uruguayan" },
  { value: "uzbek", label: "Uzbek" },
  { value: "vanuatu", label: "Vanuatuan" },
  { value: "venezuelan", label: "Venezuelan" },
  { value: "vietnamese", label: "Vietnamese" },
  { value: "yemeni", label: "Yemeni" },
  { value: "zambian", label: "Zambian" },
  { value: "zimbabwean", label: "Zimbabwean" },
];

export default function Checkoutvol({ voya, place, adult, children, total, bebe, voyage_id,onBack  }) {
  const location = useLocation();

  const [formData, setFormData] = useState({
    nom: "",
    surnom: "",
    email: "",
    street: "",
    type: "HOTEL",
    n_adult: adult,
    n_enfant: children,
    n_bebe: bebe,
    checkin_date: "",
    checkout_date: "",
    n_passport: "",
    nationality: "",
    code_postal: "",
    acceptedTerms: false,
    voyage_id: voyage_id || "",
    totalAmount: total,
    userId: localStorage.getItem("userId"),
  });



  const [errors, setErrors] = useState({});


    // Toast notification functions
    const notifySuccess = () => 
      toast.success("Réservation effectuée avec succès!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
  
    const notifyLoginRequired = () => 
      toast.error("Vous devez vous connecter d'abord!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
  
    const notifyError = (message) => 
      toast.error(`Erreur: ${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const depart = params.get("depart");
    const arrivage = params.get("arrivage");

    setFormData((prevData) => ({
      ...prevData,
      checkin_date: depart ? new Date(depart).toISOString() : "",
      checkout_date: arrivage ? new Date(arrivage).toISOString() : "",
    }));
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({ ...prevData, acceptedTerms: checked }));
    setErrors((prevErrors) => ({ ...prevErrors, acceptedTerms: null }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.nom) newErrors.nom = "Nom est requis.";
    if (!formData.surnom) newErrors.surnom = "Surnom est requis.";
    if (!formData.email) newErrors.email = "Email est requis.";
    if (!formData.street) newErrors.street = "Adresse est requise.";
    if (!formData.n_passport) newErrors.n_passport = "Numéro de passeport est requis.";
    if (!formData.nationality) newErrors.nationality = "Nationalité est requise.";
    if (!formData.code_postal) newErrors.code_postal = "Code postal est requis.";
    if (!formData.acceptedTerms) newErrors.acceptedTerms = "Vous devez accepter les termes et conditions.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePayment = async () => {

    const token = localStorage.getItem("protoken");

    if (!token) {
      notifyLoginRequired();
      return;
    }


    if (!validateForm()) {
      return; // Exit if validation fails
    }

    try {
      const token = localStorage.getItem("protoken");
      const response = await API.post("/reservations", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

    
      notifySuccess();
      
      setTimeout(() => {
        window.location.href ="/";
      }, 3000);
    } catch (error) {
      console.error("Error submitting data:", error);
      window.location.href = "/login";
    }
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "98%",
      height: "50px",
      minWidth: "100px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      margin: "0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "50px",
    }),
  };

  return (
    <div className="Checkout_vol">
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="main">
        <p className="big_title">Checkout</p>
        <div className="container">
          <div className="form">
            <form>
              <p className="title">Information générale</p>
              <div className="two_inputs">
                <label>
                  <p className="input_label">Nom</p>
                  <input
                    type="text"
                    placeholder="Mohamed"
                    name="nom"
                    value={formData.nom}
                    onChange={handleInputChange}
                  />
                  {errors.nom && <span className="error_badge">{errors.nom}</span>}
                </label>
                <label>
                  <p className="input_label">Surnom</p>
                  <input
                    type="text"
                    placeholder="El Amine"
                    name="surnom"
                    value={formData.surnom}
                    onChange={handleInputChange}
                  />
                  {errors.surnom && <span className="error_badge">{errors.surnom}</span>}
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Email Address</p>
                  <input
                    type="email"
                    placeholder="mohamed.elamine@example.com"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && <span className="error_badge">{errors.email}</span>}
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Street Address</p>
                  <input
                    type="text"
                    name="street"
                    placeholder="batna street 300"
                    value={formData.street}
                    onChange={handleInputChange}
                  />
                  {errors.street && <span className="error_badge">{errors.street}</span>}
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Passport Number</p>
                  <input
                    type="text"
                    name="n_passport"
                    placeholder="0123456"
                    value={formData.n_passport}
                    onChange={handleInputChange}
                  />
                  {errors.n_passport && <span className="error_badge">{errors.n_passport}</span>}
                </label>
              </div>
              <div className="two_inputs">
                <label>
                  <p className="input_label" style={{zIndex:999}}>Nationalité</p>
                  <Select
                    styles={styles}
                    options={nationalities}
                    placeholder="Select Nationality"
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        nationality: selectedOption.value,
                      }))
                    }
                  />
                  {errors.nationality && <span className="error_badge">{errors.nationality}</span>}
                </label>
                <label>
                  <p className="input_label">Zip/Postal Code</p>
                  <input
                    type="text"
                    name="code_postal"
                    value={formData.code_postal}
                    onChange={handleInputChange}
                  />
                  {errors.code_postal && <span className="error_badge">{errors.code_postal}</span>}
                </label>
              </div>
              <div className="check">
                <label>
                  <input
                    type="checkbox"
                    checked={formData.acceptedTerms}
                    onChange={handleCheckboxChange}
                  />
                  <p className="text">
                    Please check to acknowledge our <a href="/privacy">Privacy & Terms Policy</a>
                  </p>
                  
                </label>
                {errors.acceptedTerms && <span className="error_badge">{errors.acceptedTerms}</span>}
              </div>
            </form>
          </div>
          <div className="facture">
            <Volcard
              fullwidth={true}
              airline="ASL Airlines France"
              departureTime="18:00"
              arrivalTime="20:30"
              departureDate="1 Mars 2023"
              arrivalDate="1 Mars 2023"
              departureAirport="Houari Boumediene (ALG)"
              arrivalAirport="Charles De Gaulle (CDG)"
              duration="2h 30m"
              voya={voya}
            />
            <div className="content">
              <p className="title">Récapitulatif de facturation</p>
              <div className="el_container">
                <div className="el">
                  <p className="info">{place.name} - ({adult}) adult</p>
                  <p className="price">{place.price} DZD</p>
                </div>
                {children > 0 && (
                  <div className="el">
                    <p className="info">Children ({children})</p>
                    <p className="price">{children * place.price_child} DZD</p>
                  </div>
                )}
                {bebe > 0 && (
                  <div className="el">
                    <p className="info">Bébé ({bebe})</p>
                    <p className="price">{bebe * place.price_enfant} DZD</p>
                  </div>
                )}
              </div>
              <div className="line"></div>
              <div className="el">
                <p className="total">Grand Total</p>
                <p className="total_price">{total} DZD</p>
              </div>
              <div className="el">
                <button className="payement1" onClick={handlePayment}>
                  <p>Paiment dans bureau</p>
                </button>
                <button 
          className="payement2" 
          onClick={onBack}
          
        >
          <p>Retour</p>
        </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
