import "./partners.css";
import React from "react";
import { motion } from "framer-motion";
import {
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  travelcard_image2,
} from "../../assets/images/images";

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const staggerLogos = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.2 },
  },
};

const logoVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};

export default function Partners() {
  return (
    <motion.div
      className="Partners"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      style={{marginTop:"100px"}}
    >
      <motion.div className="left_part" variants={fadeIn}>
        <div className="informations">
          <motion.p className="title" variants={fadeIn}>
            Partenaires aériens
          </motion.p>
          <motion.p className="disc" variants={fadeIn}>
          Nous collaborons avec les plus grandes compagnies aériennes pour offrir des services fiables et confortables. Ces partenariats garantissent un réseau mondial, des vols flexibles et une qualité irréprochable, rendant chaque voyage agréable.
          </motion.p>
        </div>
        <motion.div className="logos" variants={staggerLogos}>
          <motion.div className="logo" variants={logoVariants}>
            <img width={"179px"} height={"43px"} src={logo1} alt="logo" loading="lazy" />
          </motion.div>
          <motion.div className="logo" variants={logoVariants}>
            <img width={"179px"} height={"43px"} src={logo2} alt="logo" loading="lazy" />
          </motion.div>
          <motion.div className="logo" variants={logoVariants}>
            <img width={"179px"} height={"43px"} src={logo3} alt="logo" loading="lazy" />
          </motion.div>
          <motion.div className="logo" variants={logoVariants}>
            <img width={"179px"} height={"43px"} src={logo4} alt="logo" loading="lazy" />
          </motion.div>
          <motion.div className="logo" variants={logoVariants}>
            <img width={"179px"} height={"43px"} src={logo5} alt="logo" loading="lazy" />
          </motion.div>
          <motion.div className="logo" variants={logoVariants}>
            <img width={"179px"} height={"43px"} src={logo6} alt="logo" loading="lazy" />
          </motion.div>
        </motion.div>
      </motion.div>
      <motion.div
        className="right_part"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <img src={travelcard_image2} alt="travel" loading="lazy" />
      </motion.div>
    </motion.div>
  );
}
