import React, { useState } from 'react';
import './reservationdemandes.css';

const ReservationCard = ({ type, details }) => {
  const getStatusClass = (status) => {
    switch(status) {
      case 'PENDING': return 'status-pending';
      case 'APPROVED': return 'status-approved';
      case 'REJECTED': return 'status-rejected';
      default: return '';
    }
  };

  const getPaymentStatusClass = (isPayed) => {
    return isPayed ? 'status-approved' : 'status-pending';
  };

  const renderCardContent = () => {
    switch (type) {
      case 'VISA':
        return (
          <div className="reservation-card visa-card">
            <h3 className="reservation-card-title">Visa Reservation</h3>
            <div className="reservation-details">
              <div className="reservation-detail">
                <span className="reservation-detail-label">Country</span>
                <span className="reservation-detail-value">{details.country}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Visa Type</span>
                <span className="reservation-detail-value">{details.visaType}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Applicant</span>
                <span className="reservation-detail-value">{details.applicantName} {details.applicantSurname}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Status</span>
                <span className={`reservation-status ${getStatusClass(details.status)}`}>
                  {details.status}
                </span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Payment</span>
                <span className={`reservation-status ${getPaymentStatusClass(details.ispayed)}`}>
                  {details.ispayed ? 'Paid' : 'Not Paid Yet'}
                </span>
              </div>
            </div>
          </div>
        );
      
      case 'HOTEL':
        return (
          <div className="reservation-card hotel-card">
            <h3 className="reservation-card-title">Hotel Reservation</h3>
            <div className="reservation-details">
              <div className="reservation-detail">
                <span className="reservation-detail-label">Name</span>
                <span className="reservation-detail-value">{details.name || details.nom} {details.surnom}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Check-in</span>
                <span className="reservation-detail-value">
                  {new Date(details.checkinDate || details.checkin_date).toLocaleDateString()}
                </span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Check-out</span>
                <span className="reservation-detail-value">
                  {new Date(details.checkoutDate || details.checkout_date).toLocaleDateString()}
                </span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Total Nights</span>
                <span className="reservation-detail-value">{details.totalNights || details.totalNights}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Status</span>
                <span className={`reservation-status ${getStatusClass(details.status)}`}>
                  {details.status}
                </span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Payment</span>
                <span className={`reservation-status ${getPaymentStatusClass(details.ispayed)}`}>
                  {details.ispayed ? 'Paid' : 'Not Paid Yet'}
                </span>
              </div>
            </div>
          </div>
        );
      
      case 'VOYAGE':
        return (
          <div className="reservation-card voyage-card">
            <h3 className="reservation-card-title">Voyage Reservation</h3>
            <div className="reservation-details">
              <div className="reservation-detail">
                <span className="reservation-detail-label">Name</span>
                <span className="reservation-detail-value">{details.nom} {details.surnom}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Email</span>
                <span className="reservation-detail-value">{details.email}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Nationality</span>
                <span className="reservation-detail-value">{details.nationality}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Adults</span>
                <span className="reservation-detail-value">{details.n_adult}</span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Status</span>
                <span className={`reservation-status ${getStatusClass(details.status)}`}>
                  {details.status}
                </span>
              </div>
              <div className="reservation-detail">
                <span className="reservation-detail-label">Payment</span>
                <span className={`reservation-status ${getPaymentStatusClass(details.ispayed)}`}>
                  {details.ispayed ? 'Paid' : 'Not Paid Yet'}
                </span>
              </div>
            </div>
          </div>
        );
      
      default:
        return <div className="reservation-card">Unknown Reservation Type</div>;
    }
  };

  return renderCardContent();
};

export default function Reservationdemandes({ userData }) {
  // Combine all reservations into a single array with type
  const allReservations = [
    ...(userData.visas || []).map(visa => ({ type: 'VISA', details: visa })),
    ...(userData.hotelReservations || []).map(hotel => ({ type: 'HOTEL', details: hotel })),
    ...(userData.reservations || []).map(voyage => ({ type: 'VOYAGE', details: voyage }))
  ];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const reservationsPerPage = 4;

  // Calculate pagination
  const indexOfLastReservation = currentPage * reservationsPerPage;
  const indexOfFirstReservation = indexOfLastReservation - reservationsPerPage;
  const currentReservations = allReservations.slice(indexOfFirstReservation, indexOfLastReservation);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(allReservations.length / reservationsPerPage);

  return (
    <div className="reservationdemandes-container">
      <h2 style={{ 
        textAlign: 'center', 
        marginBottom: '20px', 
        color: 'var(--text-color)',
        fontSize: '2rem'
      }}>
        Your Reservations
      </h2>
      {allReservations.length === 0 ? (
        <p style={{ 
          textAlign: 'center', 
          color: 'var(--text-color)', 
          padding: '20px' 
        }}>
          No reservations found.
        </p>
      ) : (
        <>
          <div className="reservations-grid">
            {currentReservations.map((reservation, index) => (
              <ReservationCard 
                key={`${reservation.type}-${index}`} 
                type={reservation.type} 
                details={reservation.details} 
              />
            ))}
          </div>

          {/* Pagination */}
          <div className="pagination">
            {currentPage > 1 && (
              <button 
                onClick={() => paginate(currentPage - 1)}
                className="pagination-button"
              >
                Previous
              </button>
            )}

            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}

            {currentPage < totalPages && (
              <button 
                onClick={() => paginate(currentPage + 1)}
                className="pagination-button"
              >
                Next
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}