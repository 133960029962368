import React, { useEffect, useState } from "react";
import "./tarif.css";
import { useParams } from "react-router-dom";

export default function Tarif({ places, departures }) {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [duration, setDuration] = useState("");

  const handleMonthChange = (e) => setSelectedMonth(e.target.value);
  const handleYearChange = (e) => setSelectedYear(e.target.value);
  const handleDurationChange = (e) => setDuration(e.target.value);

  const code = useParams()


  const handleReserve = (departure) => {
   
    const todayDate = new Date().toISOString().split("T")[0];
    window.location.href = `/reserve/${code.code}?depart=${departure.start}&arrivage=${departure.end}`;
   
  };

  // Filtered list of departures based on selected filters
  const filteredDepartures = departures.filter((departure) => {
    const departureStart = new Date(departure.start);
    const departureEnd = new Date(departure.end);
    const durationDays = Math.floor((departureEnd - departureStart) / (1000 * 60 * 60 * 24));

    const matchesMonthYear =
      (!selectedMonth || departureStart.getMonth() + 1 === parseInt(selectedMonth)) &&
      (!selectedYear || departureStart.getFullYear() === parseInt(selectedYear));
    const matchesDuration = !duration || durationDays === parseInt(duration);

    return matchesMonthYear && matchesDuration;
  });

  // Get single child and additional prices (assuming all prices are the same)
  const childPrice = places.length > 0 ? places[0].price_child : null;
  const additionalPrice = places.length > 0 ? places[0].price_enfant : null;

  return (
    <div className="tarif-section">
      <h2>Tarifs</h2>
      <p className="tarif-subtitle">
        Découvrez nos options tarifaires pour chaque départ. Consultez les tarifs selon le nombre d'adultes, avec un tarif enfant unique pour tous les départs.
      </p>

      <div className="filters" style={{backgroundColor:"#ff6600"}}>
        <label>
          Mois:
          <select value={selectedMonth} onChange={handleMonthChange}>
            <option value="">Tous</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(0, i).toLocaleString("fr-FR", { month: "long" })}
              </option>
            ))}
          </select>
        </label>

        <label>
          Année:
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="">Tous</option>
            {Array.from(new Set(departures.map((d) => new Date(d.start).getFullYear()))).map(
              (year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            )}
          </select>
        </label>

        <label>
          Durée (en jours):
          <input
            type="number"
            min="1"
            placeholder="Nombre de jours"
            value={duration}
            onChange={handleDurationChange}
            style={{height:"19px"}}
          />
        </label>
      </div>

      <div className="departures-header">
        <span>Départ</span>
        <span>Retour</span>
        <span>Action</span>
      </div>

      <ul className="tarif-list">
        {filteredDepartures.length > 0 ? (
          filteredDepartures.map((departure, index) => (
            <li key={index} className="tarif-item">
              <div className="departure-info">
                <span>{new Date(departure.start).toLocaleDateString("fr-FR")}</span>
                <span>{new Date(departure.end).toLocaleDateString("fr-FR")}</span>
                <button className="reserve-button" onClick={() => handleReserve(departure)}>
                  Réserver
                </button>
              </div>
            </li>
          ))
        ) : (
          <p>Aucun départ ne correspond aux critères de filtrage.</p>
        )}
      </ul>

      <strong style={{ fontFamily: "Arial, sans-serif", fontSize: "1.2em", color: "#333" }}>Tarifs:</strong>
<ul className="tarif-details">
  <li><strong>Chambres disponibles :</strong> Nos chambres peuvent accueillir jusqu'à {places.reduce((acc, place) => acc + place.max_adults, 0)} adultes, avec plusieurs options disponibles :</li>
  <ul className="places-list">
    {places.map((place, idx) => (
      <li key={idx}>
        Pour {place.max_adults} adultes, le tarif est de {place.price} DZD par nuit.
      </li>
    ))}
  </ul>
  <li><strong>Pour les enfants :</strong> Un tarif spécial pour les enfants est fixé à {childPrice} DZD, leur permettant de profiter du confort de nos installations à un prix réduit.</li>
  <li><strong>Tarif supplémentaire :</strong> Tout adulte supplémentaire peut séjourner pour un tarif additionnel de {additionalPrice} DZD par nuit, afin d'assurer le confort optimal pour chaque invité.</li>
</ul>

    </div>
  );
}
