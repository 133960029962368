import { hidenpswIcon, seepswIcon } from "../../assets/images/icons";
import { logofaded } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";
import Topbar from "../../components/Topbar/Topbar";
import "./login.css";

import React, { useState } from "react";
import API from "../../API"; // Ensure you import your API service
import ClipLoader from "react-spinners/ClipLoader"; // Import the loader

export default function Login() {
  const [seepsw, setSeepsw] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrorMessage(''); // Clear error message on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear previous error message
    setLoading(true); // Set loading to true when form is submitted

    // Password length validation
    if (formData.password.length < 8) {
      setErrorMessage("Le mot de passe doit comporter au moins 8 caractères.");
      setLoading(false); // Stop loading when validation fails
      return; // Stop execution if validation fails
    }

    try {
      const response = await API.post('/auth/login/user', formData);
      if (response.status === 201) {
        const { token, firstname, lastname, email, id } = response.data;
        localStorage.setItem("protoken", token);
        localStorage.setItem("fullname", `${firstname} ${lastname}`);
        localStorage.setItem("email", email);
        localStorage.setItem("userId", id);
        window.location.href = '/'; 
      }
    } catch (error) {
      console.error("Login error:", error);
      if (error.response) {
        // Server responded with a status other than 2xx
        setErrorMessage(error.response.data.message || "Server error, please try again.");
      } else {
        // Network error or request did not reach the server
        setErrorMessage("Email or password is incorrect, please try again.");
      }
    } finally {
      setLoading(false); // Stop loading when the request finishes
    }
  };

  return (
    <div className="Page_login">
      <Topbar />
      
      <div className="main">
        <div className="left_part" id="lefto"><img src={logofaded} alt="logo" />

        </div>
        <div className="right_part" id="yuki">
          <p className="title">Connexion</p>
          <form onSubmit={handleSubmit}>
            <label>
              <p>Adresse mail</p>
              <input
                placeholder="Entrer votre email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <p>Mot de passe</p>
              <div className="password">
                <input
                  placeholder="Entrer le mot de passe"
                  type={seepsw ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <img
                  onClick={() => setSeepsw((state) => !state)}
                  src={seepsw ? seepswIcon : hidenpswIcon}
                  alt="password visibility toggle"
                />
              </div>
            </label>
            <div className="row">
              <div className="check">
                <input type="checkbox" /><p className="checkboxtext">Enregistrer mes infos</p>
              </div>
              <p className="blue"><a href="/forgot-password">Mot de passe oublié?</a></p>
            </div>
            <button type="submit" disabled={loading}> {/* Disable button when loading */}
              {loading ? (
                <div className="loader-container">
                  <ClipLoader color={"#123abc"} loading={loading} size={20} />
                </div>
              ) : (
                <p>S'identifier</p>
              )}
            </button>
            {errorMessage && <p className="error-message" style={{color:'red', position:'absolute', marginTop:'50px',fontSize:'12px'}}>{errorMessage}</p>} {/* Display error message */}
          </form>
          <div className="line"></div>
          <p className="register">Vous n'avez pas de compte ? <a href="/register">Créer un compte</a> !</p>
          <a href="/" className="home-link">
            Retourner à l'accueil
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
