import React, { lazy, Suspense } from "react";
import SwiperCore from "swiper";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import "./carousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { image1, image2, image3 } from "../../assets/images/carousel";

// Fallback component for loading state
const LoadingFallback = () => (
  <div className="carousel-loading">
    <div className="loading-placeholder animate-pulse">
      <div className="image-placeholder bg-gray-300"></div>
      <div className="content-placeholder">
        <div className="title-placeholder bg-gray-200 h-6 w-3/4 mb-2"></div>
        <div className="description-placeholder bg-gray-200 h-4 w-full mb-1"></div>
      </div>
    </div>
  </div>
);

// Carousel Slides Configuration
const carouselSlides = [
  {
    image: image1,
    alt: "Dubai Image",
    title: "Voyage organisé à",
    highlightedTitle: "Dubai",
    description: "Découvrez la magie de Dubaï, une ville où le luxe, la modernité et la culture arabe se rencontrent. Explorez des gratte-ciels emblématiques comme le Burj Khalifa, détendez-vous dans des complexes hôteliers somptueux, et profitez d'une aventure unique dans le désert. Dubaï, c'est aussi des expériences inoubliables comme le ski en intérieur, des safaris en 4x4, et des balades en bateau traditionnel à travers la marina scintillante. Laissez-vous séduire par cette destination où tout semble possible."
  },
  {
    image: image2,
    alt: "Turkey Image",
    title: "Voyage organisé en",
    highlightedTitle: "Turquie",
    description: "Plongez dans l'histoire et la richesse culturelle de la Turquie, un pays où l'Orient rencontre l'Occident. Flânez dans les ruelles colorées d'Istanbul, visitez des sites historiques comme la Mosquée Bleue et le palais de Topkapi, ou laissez-vous émerveiller par les paysages féeriques de la Cappadoce. Dégustez une cuisine délicieuse avec des plats comme le kebab et les loukoums, tout en découvrant l'hospitalité légendaire des habitants. La Turquie promet une aventure inoubliable entre tradition et modernité."
  },
  {
    image: image3,
    alt: "Malta Image",
    title: "Voyage organisé à",
    highlightedTitle: "Malte",
    description: "Découvrez Malte, un joyau de la Méditerranée, où chaque coin de rue raconte une histoire fascinante. Promenez-vous dans les ruelles de La Valette, explorez des temples préhistoriques, et détendez-vous sur des plages de sable doré ou au bord d'eaux turquoise. Que ce soit pour une plongée dans l'une des meilleures eaux d'Europe, une croisière autour de l'archipel, ou une immersion dans l'histoire médiévale, Malte offre une escapade idéale mêlant culture, nature et sérénité."
  }
];

// Initialize Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Carousel() {
  const handleNavigate = () => {
    window.location.href = "/voyage";
  };

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Swiper
        className="custom-swiper"
        direction="vertical"
        pagination={{ 
          clickable: true, 
          el: '.swiper-pagination', 
          type: 'bullets' 
        }}
        autoplay={{ delay: 3000 }}
        allowTouchMove={false}
      >
        {carouselSlides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide_cn" style={{ position: 'relative' }}>
              <img 
                src={slide.image} 
                alt={slide.alt} 
                style={{ 
                  width: "100%", 
                  height: "100%", 
                  objectFit: "cover" 
                }} 
                loading="lazy" 
              />
              <p className="img_text img_title">
                {slide.title} <span className="blue_text">{slide.highlightedTitle}</span>
              </p>
              <p className="img_text img_disc">
                {slide.description}
              </p>
              <button 
                className="voir_offres"
                onClick={handleNavigate}
              >
                Voir les offres
              </button>
            </div>
          </SwiperSlide>
        ))}
        <div 
          className="swiper-pagination" 
          style={{ left: 0, marginLeft: '20px' }} 
        />
      </Swiper>
    </Suspense>
  );
}

// For code splitting
export const LazyCarousel = lazy(() => import('./Carousel'));