export const API_URL ={
    development:"https://myprotour.com/api",
    production: "https://myprotour.com/api"
  }

//"http://localhost:4040/api"

export const API_URL2 = {
    development: "https://myprotour.com/api/hotel/",
    production: "https://myprotour.com/api/hotel/",
  };

//"http://localhost:4200/api"

export const API_URL_IMAGE ={
  development:"https://myprotour.com/",
  production: "https://myprotour.com/"
}

//"http://localhost:4040/"

export const API_URL_IMAGE2 ={
  development:"https://myprotour.com/hotel/",
  production: "https://myprotour.com/hotel/"
}

//"http://localhost:4200/"