import React from "react";
import { starIcon } from "../../assets/images/icons";
import { useNavigate } from "react-router-dom";
import "./horizontalhotelcard.css";
import { API_URL_IMAGE } from "../../constants";

export default function HorizontalHotelCard({ property }) {
    const navigate = useNavigate();



    // Get first image or use placeholder
    const firstImage = `${API_URL_IMAGE[process.env.NODE_ENV]}${property.images?.[0]?.url}` ;

    // Get first place's price or use a default
    const price = property.startprice || 10000;

    return (
        <div className="horizontal-hotel-card">
            <img src={firstImage} alt="hotel" className="card-img" />
            <div className="card-info">
                <h3 className="card-title">{property.name}</h3>
                <p className="card-location">{property.location}</p>
                <p className="card-description">
                    {property.description || "A comfortable stay with great amenities."}
                </p>
                <div className="card-features">
                    <h4>Services</h4>
                    {property.services?.map((service, index) => (
                        <span key={index} className="feature">{service}</span>
                    ))}
                </div>


            </div>
            <div className="card-reservation">
                <p className="price">
                    <span className="price-value">
                        {price} <span className="price-unit">DA / night</span>
                    </span>
                </p>
                <button 
  onClick={() => {
    if (property?.location) {
      // Redirect to the hotel page if location exists
      window.location.href = `/hotel-one/${property.code}`;
    } else {
      // Redirect to another location (e.g., `#`) if no location is present
      window.location.href = "#";
    }
  }} 
  className="reserve-button"
>
  Book Now
</button>

            </div>
        </div>
    );
}