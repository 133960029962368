import React, { useState, useEffect } from "react";
import axios from "axios";
import Topbar from "../../components/Topbar/Topbar";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Horizontalhotelcard from "../../components/Horizontalhotelcard/Horizontalhotelcard";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Pagination from "@mui/material/Pagination";
import { Alert, CircularProgress } from "@mui/material";

import {
  filtersIcon,
} from "../../assets/images/icons";
import "./hotels.css";
import API from "../../API";

function valuetext(value) {
  return `${value} DA`;
}

export default function Hotels() {
  // State for filters and search
  const [location, setLocation] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [priceRange, setPriceRange] = useState([0, 200000]);
  
  // State for search results and pagination
  const [properties, setProperties] = useState([]);
  const [totalProperties, setTotalProperties] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);

  // Loading and error states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Price range slider handler
  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  // Search handler
  const searchProperties = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await API.get('/properties/prop/search', {
        params: {
          location,
          name: hotelName,
          minPrice: priceRange[0],
          maxPrice: priceRange[1],
          page: currentPage,
          pageSize
        }
      });

     

      // Ensure response data is in expected format
      const { properties: fetchedProperties = [], total = 0 } = response.data;
      
      setProperties(fetchedProperties);
      setTotalProperties(total);
    } catch (error) {
      console.error("Error searching properties", error);
      
      // More detailed error handling
      setError(
        error.response?.data?.message || 
        error.message || 
        "An unexpected error occurred"
      );
      
      // Reset properties on error
      setProperties([]);
      setTotalProperties(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Pagination handler
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Effect to trigger search when filters change or page changes
  useEffect(() => {
    searchProperties();
  }, [currentPage, location, hotelName, priceRange]);

  return (
    <div className="Page_hotels">
      <Topbar />
      <Navbar page={"hotels"}/>

      <div className="p2">
        <div className="left_part">
          <div className="title">
            <p>Filtres</p>
            <img src={filtersIcon} alt="filters" />
          </div>
          <div className="kiko">
            <div className="label">
              <p>Location</p>
              <input 
                type="text" 
                name="location" 
                placeholder="Setif"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="label">
              <p>Nom d'hotel</p>
              <input 
                type="text" 
                name="name" 
                placeholder="i.e hotel Setif"
                value={hotelName}
                onChange={(e) => setHotelName(e.target.value)}
              />
            </div>
            <div className="label">
              <p>Budget</p>
              <Box sx={{ width: "270px" }}>
                <Slider
                  min={0}
                  max={1000000}
                  step={100}
                  getAriaLabel={() => "Price"}
                  value={priceRange}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                />
              </Box>
              <div className="range">
                <p>
                  <span className="golden_tag">{priceRange[0]}</span>
                </p>
                <p>-</p>
                <p>
                  <span className="blue_tag">{priceRange[1]}</span>
                </p>
                <p>
                  <span className="dark_blue_tag">DZD</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="right_part">
          <p className="title">
            Résultats correspondant à "{location || hotelName || 'Tous'}"
          </p>

          {/* Loading State */}
          {isLoading && (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
              <CircularProgress />
            </div>
          )}

          {/* Error State */}
          {error && (
            <Alert severity="error" style={{ margin: '20px 0' }}>
              {error}
            </Alert>
          )}

          {/* Properties List */}
          {!isLoading && !error && properties.map(property => (
            <Horizontalhotelcard 
              key={property.id}
              property={property}
            />
          ))}

          {/* No Results */}
          {!isLoading && !error && properties.length === 0 && (
            <p>Aucune propriété trouvée</p>
          )}

          {/* Pagination */}
          {!isLoading && !error && properties.length > 0 && (
            <div className="pagination">
              <Pagination 
                count={Math.ceil(totalProperties / pageSize)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"

                style={{marginTop:"30px"}}
              />
            </div>
          )}
        </div>
      </div>
      
      <Footer />
    </div>
  );
}