import React from "react";
import "./description.css";

export default function Description({ description }) {
  return (
    
<div className="description-section">
      <h2>Description de voyage</h2>
      <p className="description-text">{description}</p>
    </div>
    
    
  );
}
