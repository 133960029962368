import './App.css';
import Carousel from './components/Carousel/Carousel';
import Navbar from './components/Navbar/Navbar';
import Topbar from './components/Topbar/Topbar';
import Checkouthotel from './pages/Checkouthotel/Checkouthotel';
import Checkoutvol from './pages/Checkoutvol/Checkoutvol';
import Checkoutvoyage from './pages/Checkoutvoyage/Checkoutvoyage';
import Home from './pages/Home/Home';
import Hotel from './pages/Hotel/Hotel';
import Hotels from './pages/Hotels/Hotels';
import Visa from './pages/Visa/Visa';
import Vols from './pages/Vols/Vols';
import Voyage from './pages/Voyage/Voyage';
import Voyagedetails from './pages/Voyagedetails/Voyagedetails';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import Us from './pages/Us/Us';
import Notfound from './pages/Notfound/Notfound';
import Register from './pages/Register/Register';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { isAuth } from './utils/auth';
import { useState, useEffect } from 'react';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgotPassword from './pages/ForgetPassword/ForgotPassword';
import Voyageorg from './pages/Voyageorg/Voyageorg';
import Reserve from './pages/Reserve/Reserve';

function App() {
  const [auth, setAuth] = useState(isAuth());

  useEffect(() => {
    // Check if token has expired (2 hours limit)
    const setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", new Date().getTime());
    } else {
      if (new Date().getTime() - setupTime > 2 * 60 * 60 * 1000) {
        localStorage.removeItem("protoken");
        localStorage.removeItem("fullname");
        localStorage.removeItem("email");
        localStorage.removeItem("userId");
        localStorage.removeItem("setupTime");
        localStorage.removeItem("profileImage");
        localStorage.removeItem("protoken");
        localStorage.setItem("setupTime", new Date().getTime());
        setAuth(false);  // Update auth state after token removal
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {auth ? (
          <>
            <Route path='/' element={<Home />} />

            <Route path='/hcheckout' element={<Checkouthotel />} />
            <Route path='/vcheckout' element={<Checkoutvol />} />
            <Route path='/visa' element={<Visa />} />
            <Route path='/voyage' element={<Voyage />} />
            <Route path='/cvoyage' element={<Checkoutvoyage />} />
            <Route path='/us' element={<Us />} />
            <Route path='/hotel-one/:code' element={<Hotel/>}/>

            <Route path='/hotels' element={<Hotels/>}/>
            <Route path='/vols' element={<Vols/>}/>
            <Route path='/profile' element={<Profile />} />
            <Route path='/voyage-org/:code' element={<Voyageorg />} />
            <Route path='/reserve/:code' element={<Reserve />} />
            <Route path='/login' element={<Navigate to="/" replace />} />
            <Route path='*' element={<Notfound />} />
            
          </>
        ) : (
          <>
            <Route path='/vcheckout' element={<Checkoutvol />} />
            <Route path='/us' element={<Us />} />
            <Route path='/visa' element={<Visa />} />
            <Route path='/voyage' element={<Voyage />} />
            <Route path='/cvoyage' element={<Checkoutvoyage />} />
            <Route path='/hotel-one/:code' element={<Hotel/>}/>
            <Route path='/hotels' element={<Hotels/>}/>
            <Route path='/hcheckout' element={<Checkouthotel />} />
            <Route path='/vols' element={<Vols/>}/>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/voyage-org/:code' element={<Voyageorg />} />
            <Route path='/' element={<Home />} />
            <Route path='/reserve/:code' element={<Reserve />} />

            <Route path='*' element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
