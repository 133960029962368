import { hidenpswIcon, seepswIcon } from "../../assets/images/icons";
import { logofaded } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";
import Topbar from "../../components/Topbar/Topbar";
import "./register.css";

import React, { useState } from "react";
import API from "../../API";
import Joi from "joi";
import ClipLoader from "react-spinners/ClipLoader"; // Import ClipLoader for loading spinner

export default function Register() {
  const [seepsw, setSeepsw] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState([]); // State for error messages as an array
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors([]); // Clear errors on change
  };

  const customTLDs = ['com', 'net', 'org', 'io', 'co', 'us'];

  const schema = Joi.object({
    firstname: Joi.string().min(1).required().label('First Name'),
    lastname: Joi.string().min(1).required().label('Last Name'),
    phone: Joi.string().length(10).pattern(/^[0-9]+$/).required().label('Phone Number'),
    email: Joi.string().email({ tlds: { allow: customTLDs } }).required().label('Email'),
    password: Joi.string().min(8).required().label('Password')
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const messages = error.details.map(detail => detail.message);
      setErrors(messages); // Set error messages in state as an array
      return;
    }

    setLoading(true); // Set loading state to true when form is submitted

    try {
    
      const response = await API.post('/auth/signup/user', formData);
      if (response.status === 201) {
       
        window.location.href = 'login';
      }
    } catch (error) {
      console.error("Signup error:", error);
      setErrors(["An error occurred. Please try again."]); // Generic error message
    } finally {
      setLoading(false); // Stop loading once the API request is complete
    }
  };
  
  return (
    <div className="Page_register">
      <Topbar />
      
      <div className="main">
        <div className="left_part"><img src={logofaded} alt="logo"/></div>
        <div className="right_part">
          <p className="title">S'inscrire</p>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <label>
                <p>Nom</p>
                <input
                  type="text"
                  placeholder="votre Nom"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                <p>Prenom</p>
                <input
                  type="text"
                  placeholder="votre Prenom"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="row">
            <label>
              <p>Telephone</p>
              <div className="phone">
                <div className="number"><p>+213</p></div>
                <input
                  type="tel"
                  placeholder="07 77 00 00 00"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </label>
            <label>
              <p>adresse mail</p>
              <input
                placeholder="Entrer votre email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            </div>
            
            <label>
              <p>Mot de passe</p>
              <div className="password">
                <input
                  placeholder="Entrer le mot de passe"
                  type={seepsw ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <img
                  onClick={() => setSeepsw((state) => !state)}
                  src={seepsw ? seepswIcon : hidenpswIcon}
                  alt="password"
                />
              </div>
            </label>
            {errors.length > 0 && (
              <div style={{ color: 'red', marginTop: '0px',position:'relative' }}>
                {errors.map((error, index) => (
                  <p key={index} style={{ fontSize: '12px' }}>{error}</p>
                ))}
              </div>
            )} {/* Display error messages one by one */}
            <button type="submit" disabled={loading}> {/* Disable button while loading */}
              {loading ? (
                <div className="loader-container">
                  <ClipLoader color={"#123abc"} loading={loading} size={20} />
                </div>
              ) : (
                <p>Register</p>
              )}
            </button>
          </form>
          <div className="line"></div>
          <p className="register">Vous avez un compte ? <a href="/login"> s’identifier</a> !</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
