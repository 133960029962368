import "./selectfield.css";
import Select, { components } from "react-select";
import React from "react";
import { arrowdownIcon } from "../../assets/images/icons";

export default function Selectfield({
  width,
  height,
  placeholder,
  icon,
  hiddenDropdownIndicator,
  changePlaceHolderStyle,
  noIcon,
  paddingLeft,
  onChange,
  options: customOptions, // New prop to pass custom options
}) {
  // Default options if no custom options are provided
  const defaultOptions = [
    { value: "", label: placeholder }
  ];

  // Use custom options if provided, otherwise use default
  const options = customOptions?.length > 0 
    ? [
        { value: "", label: placeholder },
        ...customOptions
      ]
    : defaultOptions;

  let DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <img src={arrowdownIcon} alt="drop down" />
    </components.DropdownIndicator>
  );

  if (hiddenDropdownIndicator) {
    DropdownIndicator = () => null;
  }

  let ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <img
              src={icon}
              style={{ position: "absolute", left: 20, marginTop: 2 }}
              alt="icon"
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const styles = {
    valueContainer: (base) => ({
      ...base,
      paddingLeft: paddingLeft,
      minWidth: width,
      height: height,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    control: (base) => ({
      ...base,
    }),
    menu: (base) => ({ ...base, width: width }),
  };

  if (changePlaceHolderStyle) {
    styles.placeholder = (base) => ({
      ...base,
      color: "#0F0E0E",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: "400",
    });
  }

  return (
    <Select
      options={options}
      components={icon ? { ValueContainer, DropdownIndicator } : { DropdownIndicator }}
      styles={styles}
      placeholder={placeholder}
      onChange={(selectedOption) => {
        if (onChange) {
          onChange(selectedOption?.value || ""); // Always trigger onChange, even if empty
        }
      }}
    />
  );
}