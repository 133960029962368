import React, { useEffect } from "react";
import "./devis.css";

import hotel from "../../images/icons/hotel.png";
import plane from "../../images/icons/plane.png";
import paid from "../../images/icons/paid.png";

export default function Devis({
  places,
  adults,
  children,
  onPlaceSelect,
  setSelectedPlace,
  setTotal,
  total,
  selectedPlace,
  onBack,
  bebe
}) {
  // Calculate total cost based on the selected place and number of children
  useEffect(() => {
    if (selectedPlace) {
      const basePrice = parseInt(selectedPlace.price, 10);
      const childPrice = parseInt(selectedPlace.price_child, 10);
      const bebePrice = parseInt(selectedPlace.price_enfant, 10);
      setTotal(basePrice + childPrice * children + bebePrice *bebe );
    }
  }, [selectedPlace, children, setTotal]);

  return (
    <div className="devis-section">
      <h2>Devis</h2>
      <p>Sélectionnez un lieu parmi les options disponibles.</p>

      <div className="section">
  <h3><img src={hotel} height="20px" width="20px"/> Hébergement</h3>
  <div className="places-list">
    {places.length > 0 ? (
      places.map((place, index) => (
        <div
          key={index}
          className={`place-item ${!place.available ? "" : "unavailable"} ${selectedPlace === place ? "selected" : ""}`}
          onClick={() => !place.available && setSelectedPlace(place)}
        >
          <h4>{place.name}</h4>
          <p>Max Adultes: {place.max_adults}</p>
          <p>Max Enfants: {place.max_children}</p>
          <p>Prix: {place.price} DZD</p>
          <p>Prix Enfant: {place.price_child} DZD</p>
          <p>Prix Bébé: {place.price_enfant} DZD</p>
        </div>
      ))
    ) : (
      <p className="no-places-message">Il n'y a aucun hébergement qui correspond au nombre de voyageurs.</p>
    )}
  </div>
</div>


      <hr />

      <h3><img src={plane} height="20px" width="20px"/> Transport</h3>
      <div className="section" style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Les passagers de ce voyage : {adults} (Adults), {children} (Enfants)</p>
        <p><img src={paid} height="20px" width="20px"/> Inclus avec le voyage</p>
      </div>

      <hr />

      <div className="section total-section" style={{ backgroundColor: "#ff6600", color: "white" }}>
        <h3>Total :</h3>
        {selectedPlace ? (
          <p>
            <strong>{total} DZD</strong>
          </p>
        ) : (
          <p>Sélectionnez un hébergement pour voir le total.</p>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="back-button" onClick={onBack}>
          Retour
        </button>
        <button
          className="next-button"
          onClick={() => selectedPlace && onPlaceSelect(selectedPlace)}
        >
          Completez les procedures
        </button>
      </div>
    </div>
  );
}
