import React, { useEffect, useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import DatePicker from 'react-datepicker';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

// Component Imports
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import Carouselcard from "../../components/Carouselcard/Carouselcard";
import Footer from "../../components/Footer/Footer";

// API and Styling
import API from "../../API";
import "./voyage.css";

export default function Voyage() {
  // State Management
  const [voyages, setVoyages] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalVoyages: 0,
    pageSize: 8,
  });

  // Search Parameters State
  const [searchParams, setSearchParams] = useState({
    departureDate: null,
    arrivalDate: null,
    priceMin: '',
    priceMax: '',
  });

  // Debounced Search Parameters
  const [debouncedSearchParams, setDebouncedSearchParams] = useState(searchParams);

  // Debounce Effect
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchParams(searchParams);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchParams]);

  // Fetch Voyages Function
  const fetchVoyages = useCallback(async (page = 1) => {
 
    try {
      const response = await API.get("/voyage-organize/user/show", {
        params: {
          page,
          limit: 8,
          departureDate: searchParams.departureDate 
            ? format(searchParams.departureDate, 'yyyy-MM-dd') 
            : undefined,
          arrivalDate: searchParams.arrivalDate 
            ? format(searchParams.arrivalDate, 'yyyy-MM-dd') 
            : undefined,
          priceMin: debouncedSearchParams.priceMin || undefined,
          priceMax: debouncedSearchParams.priceMax || undefined,
        },
      });

      const paginationData = response.data.pagination;
      setPagination({
        currentPage: parseInt(paginationData.currentPage),
        totalPages: parseInt(paginationData.totalPages),
        totalVoyages: parseInt(paginationData.totalVoyages),
        pageSize: parseInt(paginationData.pageSize),
      });

      setVoyages(response.data.voyages);
    } catch (error) {
      console.error("Error fetching data:", error);
      setVoyages([]);
      setPagination({
        currentPage: 1,
        totalPages: 1,
        totalVoyages: 0,
        pageSize: 8,
      });
    }
  }, [debouncedSearchParams]);

  // Trigger Search on Parameter Changes
  useEffect(() => {
    fetchVoyages(1);
  }, [debouncedSearchParams, fetchVoyages]);

  // Input Change Handlers
  const handleDateChange = (field, date) => {
    setSearchParams((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Reset Filters
  const resetFilters = () => {
    setSearchParams({
      departureDate: null,
      arrivalDate: null,
      priceMin: '',
      priceMax: '',
    });
    fetchVoyages(1);
  };

  // Calculate Trip Duration
  const calculateDuration = (departures) => {
    if (!departures || departures.length === 0) return "";
    const startDate = new Date(departures[0].start);
    const endDate = new Date(departures[0].end);
    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    const nights = totalDays - 1;
    return `${totalDays} jours, ${nights} nuits`;
  };

  // Pagination Handler
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pagination.totalPages) {
      fetchVoyages(newPage);
    }
  };

  // Animation Variants
  const searchVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        type: "spring",
        stiffness: 120,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.2 },
    },
  };

  return (
    <div className="Page_voyage">
      <Topbar />
      <Navbar page={"voyage"} />

      <motion.div
        className="voyage-search"
        initial="hidden"
        animate="visible"
        variants={searchVariants}
      >
        <div className="container">
          <div className="search-row">
            {/* Departure Date Picker */}
            <motion.div className="input-container">
              <label htmlFor="departure-date" className="input-label">
                Date de départ
              </label>
              <DatePicker
                id="departure-date"
                selected={searchParams.departureDate}
                onChange={(date) => handleDateChange('departureDate', date)}
                placeholderText="Sélectionnez une date"
                className="datepicker"
                locale={fr}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()} 
                showDisabledMonthNavigation
              />
            </motion.div>

            {/* Arrival Date Picker */}
            <motion.div className="input-container">
              <label htmlFor="arrival-date" className="input-label">
                Date d'arrivée
              </label>
              <DatePicker
                id="arrival-date"
                selected={searchParams.arrivalDate}
                onChange={(date) => handleDateChange('arrivalDate', date)}
                placeholderText="Sélectionnez une date"
                className="datepicker"
                locale={fr}
                dateFormat="dd/MM/yyyy"
                minDate={searchParams.departureDate || new Date()} 
                showDisabledMonthNavigation
              />
            </motion.div>

            {/* Price Min Input */}
            <motion.div className="input-container">
              <label htmlFor="price-min" className="input-label">
                Prix minimum
              </label>
              <input
                id="price-min"
                type="number"
                placeholder="Entrez le prix minimum"
                value={searchParams.priceMin}
                onChange={(e) => handleInputChange("priceMin", e.target.value)}
                min="0"
                className="price-input"
              />
            </motion.div>

            {/* Price Max Input */}
            <motion.div className="input-container">
              <label htmlFor="price-max" className="input-label">
                Prix maximum
              </label>
              <input
                id="price-max"
                type="number"
                placeholder="Entrez le prix maximum"
                value={searchParams.priceMax}
                onChange={(e) => handleInputChange("priceMax", e.target.value)}
                min="0"
                className="price-input"
              />
            </motion.div>

            {/* Reset Filters Button */}
            
          </div>
        </div>
      </motion.div>

      {/* Voyages Display */}
      <div className="main">
        <div className="container">
          <AnimatePresence>
            {voyages.length > 0 ? (
              voyages.map((voyage) => (
                <motion.div
                  key={voyage.id}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={cardVariants}
                >
                  <Carouselcard
                    title={voyage.title || "Offre inconnue"}
                    location={voyage.cite || "Unknown Location"}
                    duration={calculateDuration(voyage.departures)}
                    price={voyage.places?.[0]?.price || "N/A"}
                    image={voyage.images?.[0]?.url || "default-image-path.jpg"}
                    code={voyage.code}
                  />
                </motion.div>
              ))
            ) : (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="no-results"
                
              >
                Aucun voyage trouvé
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        {/* Pagination */}
        {voyages.length > 0 && pagination.totalPages > 1 && (
          <motion.div className="pagination">
            <motion.button
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage === 1}
              className="page-button"
            >
              Précédent
            </motion.button>
            <span className="page-info">
              Page {pagination.currentPage} de {pagination.totalPages}
            </span>
            <motion.button
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage === pagination.totalPages}
              className="page-button"
            >
              Suivant
            </motion.button>
          </motion.div>
        )}
      </div>
      <Footer />
    </div>
  );
}