import React, { useState } from "react";
import "./demande.css";

export default function Demande({ onNext, onBack, setAdults, setChildren,setBebe }) {
  const [localAdults, setLocalAdults] = useState(1);
  const [localChildren, setLocalChildren] = useState(0);
  const [localBebe,setLocalBebe]=useState(0)

  const handleNext = () => {
    setAdults(localAdults);
    setChildren(localChildren);
    setBebe(localBebe)
    onNext();
  };

  return (
    <div className="demande-section">
      <h2>Votre Demande</h2>
      <p className="explanatory-text">
        Veuillez sélectionner le nombre d'adultes et d'enfants pour votre voyage.
      </p>
      <div className="input-group-container">
        <div className="input-group">
          <label>Nombre d'adultes:</label>
          <select
            value={localAdults}
            onChange={(e) => setLocalAdults(parseInt(e.target.value))}
          >
            {[...Array(10).keys()].map((num) => (
              <option key={num + 1} value={num + 1}>
                {num + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label>Nombre d'enfants:</label>
          <select
            value={localChildren}
            onChange={(e) => setLocalChildren(parseInt(e.target.value))}
          >
            {[...Array(10).keys()].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label>Nombre de bébé :</label>
          <select
            value={localBebe}
            onChange={(e) => setLocalBebe(parseInt(e.target.value))}
          >
            {[...Array(10).keys()].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="button-group">
        <button className="retour" onClick={onBack}>Retour</button>
        <button onClick={handleNext}>Voir Tarif</button>
      </div>
    </div>
  );
}
