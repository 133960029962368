import { editIcon } from "../../images/icons";
import Select from "react-select";
import "./support.css";
import API from "../../../API";
import Swal from "sweetalert2";
import React, { useState } from "react";

const SupportTypes = {
  OTHER: "autre",
  ISSUE: "problème",
  FEEDBACK: "retour",
  RESERVATION: "réservation",
  PAYMENT: "paiement",
  FLIGHT_INFO: "informations-vols",
  ACCOMMODATION: "hébergement",
  VISA_ASSISTANCE: "assistance-visa",
  TRAVEL_INSURANCE: "assurance-voyage",
  CANCELLATION: "annulation",
  SPECIAL_REQUEST: "demande-spéciale",
  LOST_PROPERTY: "objets-perdus",
  ITINERARY_MODIFICATION: "modification-itinéraire",
  EMERGENCY_ASSISTANCE: "assistance-urgence",
  GROUP_BOOKING: "réservation-groupe",
  LOYALTY_PROGRAM: "programme-de-fidélité",
  COMPLAINT: "plainte",
  TRAVEL_RESTRICTIONS: "restrictions-voyage",
};


export default function Support({ userdata }) {
  const [type, setType] = useState("autre");
  const [otherType, setOtherType] = useState("");
  const [description, setDescription] = useState("");

  const email = localStorage.getItem("email");
  const fullname = localStorage.getItem("fullname");
  const userId = localStorage.getItem("userId");
  const protoken = localStorage.getItem("protoken");

  const styles = {
    control: (provided) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "106%",
      height: "50px",
      minWidth: "100px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      margin: "0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "50px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#4F4F4F",
      fontFamily: "Poppins",
      fontSize: "16.974px",
      fontWeight: 600,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#FFFFFF" : "#4F4F4F",
      backgroundColor: state.isSelected ? "#007BFF" : "#FFFFFF",
      '&:hover': {
        backgroundColor: '#F0F0F0',
        color: '#000000',
      },
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 400,
    }),
  };
  

  // Options for "Type de prise en charge"
  const options = [
    { value: "technique", label: "Technique" },
    { value: "facturation", label: "Facturation" },
    { value: "autre", label: "Autre" },
  ];

  const handleSelectChange = (selectedOption) => {
    setType(selectedOption?.value);
  };

  const supportOptions = [
    { value: SupportTypes.OTHER, label: "Autre" },
    { value: SupportTypes.ISSUE, label: "Problème" },
    { value: SupportTypes.FEEDBACK, label: "Retour" },
    { value: SupportTypes.RESERVATION, label: "Réservation" },
    { value: SupportTypes.PAYMENT, label: "Paiement" },
    { value: SupportTypes.FLIGHT_INFO, label: "Informations Vols" },
    { value: SupportTypes.ACCOMMODATION, label: "Hébergement" },
    { value: SupportTypes.VISA_ASSISTANCE, label: "Assistance Visa" },
    { value: SupportTypes.TRAVEL_INSURANCE, label: "Assurance Voyage" },
    { value: SupportTypes.CANCELLATION, label: "Annulation" },
    { value: SupportTypes.SPECIAL_REQUEST, label: "Demande Spéciale" },
    { value: SupportTypes.LOST_PROPERTY, label: "Objets Perdus" },
    { value: SupportTypes.ITINERARY_MODIFICATION, label: "Modification Itinéraire" },
    { value: SupportTypes.EMERGENCY_ASSISTANCE, label: "Assistance Urgence" },
    { value: SupportTypes.GROUP_BOOKING, label: "Réservation Groupe" },
    { value: SupportTypes.LOYALTY_PROGRAM, label: "Programme de Fidélité" },
    { value: SupportTypes.COMPLAINT, label: "Plainte" },
    { value: SupportTypes.TRAVEL_RESTRICTIONS, label: "Restrictions Voyage" },
  ];
  

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Constructing the request body
    const data = {
      type: type,
      other_type: type === "autre" ? otherType : undefined,
      description: description,
      userId: userId,
    };

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${protoken}`,
        },
      };

      const response = await API.post("/support-tickets", data, config);
     

      // SweetAlert success message
      Swal.fire({
        icon: "success",
        title: "Ticket envoyé avec succès!",
        text: "Votre demande a été envoyée.",
        confirmButtonText: "OK",
      }).then(() => {
        // Redirect to the profile page after confirmation
        window.location.href = "/profile";
      });
    } catch (error) {
      console.error("Error submitting ticket:", error);
      alert("Il y a eu une erreur lors de l'envoi du ticket.");
    }
  };

  return (
    <div className="Support">
      <div className="title">
        <p>Informations personelles</p>
      </div>

      {/* Nom complet and Email */}
      <div className="two_inputs">
        <label>
          <p className="input_label">Nom complet</p>
          <input
            type="text"
            placeholder="Andrew Johnson"
            className="samurai"
            name="name"
            value={fullname}
            readOnly
          />
        </label>
        <label>
          <p className="input_label">Email</p>
          <input
            type="email"
            placeholder="example@domain.com"
            className="samurai"
            name="email"
            value={email}
            readOnly
          />
        </label>
      </div>

      {/* Type de prise en charge and Description */}
      <div className="two_inputs">
        <label>
          <p className="input_label z">Type de prise en charge</p>
          <Select
          styles={styles}
          options={supportOptions}
          placeholder="Sélectionner un type de support"
          value={supportOptions.find(option => option.value === type) || null}
          onChange={handleSelectChange}
        />
        </label>

        {/* Additional input for "Autre" type */}
        <label style={type === "autre" ? {} : { opacity: ".5" }}>
          <p className="input_label">Autre (si applicable)</p>
          <input
            type="text"
            placeholder="Autre prise en charge"
            value={otherType}
            onChange={(e) => setOtherType(e.target.value)}
            disabled={type !== "autre"}
          />
        </label>
      </div>

      {/* Description Text Area */}
      <div className="textfield">
        <label>
          <p className="input_label">Description</p>
          <textarea
            placeholder="Décrivez votre demande..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </label>
      </div>

      {/* Submit Button */}
      <button className="send" onClick={handleSubmit}>
        <p>Envoyer</p>
      </button>
    </div>
  );
}
