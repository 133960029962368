import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Topbar from "../../components/Topbar/Topbar";

import "./reserve.css";
import Demande from "../../assets/Forms/Demande/Demande";
import Devis from "../../assets/Forms/Devis/Devis";
import Occupation from "../../assets/Forms/Occupation/Occupation";
import Checkoutvol from "../Checkoutvol/Checkoutvol";
import API from "../../API";
import { useLocation, useParams } from "react-router-dom";

export default function Reserve() {
  const [step, setStep] = useState(1);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [bebe,setBebe]=useState(0)
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [total, setTotal] = useState(0);
  const [places, setPlaces] = useState([]);
  const [voyage_id,setVoyageId] = useState('')
  const [voya,setVoya] = useState()
  

  const code = useParams();
  const location = useLocation();

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const depart = queryParams.get("depart");
  const arrivage = queryParams.get("arrivage");
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(
          `/voyage-organize/verify/${code.code}?depart=${depart}&arrivage=${arrivage}`
        );
        
        setVoya(response.data.voyage)
        setVoyageId(response.data.voyage.id)

        const fetchedPlaces = response.data.voyage.places;

        // Filter places based on adults and children
        const filteredPlaces = fetchedPlaces.filter(
          (place) => place.max_adults >= adults && place.max_children >= children+bebe
        );

        setPlaces(filteredPlaces);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [adults, children, code.code, depart, arrivage,bebe]);

  const handleNext = () => setStep((prevStep) => Math.min(prevStep + 1, 3));
  const handleBack = () => setStep((prevStep) => Math.max(prevStep - 1, 1));

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    handleNext();
  };

  return (
    <div className="page-reservation" style={{ backgroundColor: "#F8FCFF" }}>
      <Topbar />
      <Navbar />
      <div className="reservation-content">
        <div className="forehead">
          <div className="forehead-contenta">
            <div className="step-indicator">
              <div>
                <div
                  className={`circle ${step === 1 ? "active" : ""}`}
                  
                >
                  1
                </div>
                <p>Votre demande</p>
              </div>
              <div>
                <div
                  className={`circle ${step === 2 ? "active" : ""}`}
                  
                >
                  2
                </div>
                <p>Devis</p>
              </div>
              <div>
                <div
                  className={`circle ${step === 3 ? "active" : ""}`}
                  
                >
                  3
                </div>
                <p>Occupation</p>
              </div>
            </div>
          </div>
        </div>

        <div className="step-content">
          {step === 1 && (
            <Demande
              onNext={() => handleNext()}
              onBack={handleBack}
              setAdults={setAdults}
              setChildren={setChildren}
              setBebe={setBebe}
            />
          )}
          {step === 2 && (
            <Devis
              places={places}
              adults={adults}
              children={children}
              bebe={bebe}
              onPlaceSelect={handlePlaceSelect}
              setSelectedPlace={setSelectedPlace}
              setTotal={setTotal}
              total={total}
              selectedPlace={selectedPlace}
              onBack={handleBack}
            />
          )}
          {step === 3 && (
            <Checkoutvol
              place={selectedPlace}
              adult={adults}
              children={children}
              bebe={bebe}
              total={total}
              voyage_id={voyage_id}
              onBack ={handleBack}
              voya={voya}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
