import React, { lazy, Suspense, useEffect, useState } from "react";
import { motion } from "framer-motion";

import API from "../../API";


// Icons
import {
  calendarIcon,
  locationIcon,
  logowhiteIcon,
  longrightarrowIcon,
  logo,
} from "../../assets/images/icons";

import "./home.css";

// Lazy load components
const Carousel = lazy(() => import("../../components/Carousel/Carousel"));
const Navbar = lazy(() => import("../../components/Navbar/Navbar"));
const Topbar = lazy(() => import("../../components/Topbar/Topbar"));
const LazyTravelcard = lazy(() => import("../../components/Travelcard/Travelcard"));
const LazyHotelcard = lazy(() => import("../../components/Hotelcard/Hotelcard"));
const Partners = lazy(() => import("../../components/Partners/Partners"));
const Serviceslist = lazy(() => import("../../components/Serviceslist/Serviceslist"));
const Footer = lazy(() => import("../../components/Footer/Footer"));


// Animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.2 },
  },
};

// Loading Fallback Component
const LoadingFallback = () => (
  <div className="loading-placeholder animate-pulse">
    <div className="placeholder-content bg-gray-200"></div>
  </div>
);

// Card Grid Renderer
const CardGrid = ({ 
  items, 
  CardComponent, 
  isVarient = false, 
  keyProp = 'id', 
  propName = 'voyage' 
}) => {
  const placeholderCards = 4 - items.length;
  
  return (
    <motion.div className="grid" variants={fadeIn}>
      {items.map((item, index) => (
        <CardComponent 
          key={item[keyProp] || index} 
          isVarient={isVarient} 
          {...{ [propName]: item }}
        />
      ))}
      {[...Array(placeholderCards)].map((_, index) => (
        <CardComponent key={`placeholder-${index}`} isVarient={isVarient} />
      ))}
    </motion.div>
  );
};

export default function Home() {
  const [hotels, setHotels] = useState([]);
  const [voyagenear, setVoyageNear] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = () => {
    if (searchInput.trim()) {
      window.location.href = `/voyage?location=${encodeURIComponent(searchInput)}`;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fourhotels, voyagefournear] = await Promise.all([
          API.get('properties/public/fourprop/latest'),
          API.get('voyage-organize/featured/ending-soon')
        ]);
        
        setHotels(fourhotels.data);
        setVoyageNear(voyagefournear.data);
      } catch (err) {
        console.error(err);
      }
    };
    
    fetchData();
  }, []);

  return (
    <motion.div
      className="Page_home"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={staggerContainer}
    >
      <h1 className="visually-hidden">Protour - Your Travel Companion</h1>
      
      <Suspense fallback={<LoadingFallback />}>
        <Topbar />
        <Navbar page="accueil" />
        <Carousel />
      </Suspense>

      <motion.div className="holder" variants={fadeIn}>
        <div className="search">
          <motion.div className="search_input" variants={fadeIn}>
            <motion.img src={locationIcon} loading="lazy" alt="Location" />
            <motion.input
              type="text"
              name="search"
              placeholder="where to ? i.e Bordeaux, France"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <motion.div className="calendar">
              <motion.img src={calendarIcon} loading="lazy" alt="Calendar" />
            </motion.div>
          </motion.div>
          <motion.button 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }} 
            onClick={handleSearch}
          >
            Recherche
          </motion.button>
        </div>
      </motion.div>

      <motion.div className="p1" variants={fadeIn}>
        <div className="header">
          <div className="title">
            <motion.p variants={fadeIn}>Destinations</motion.p>
          </div>
          <div className="filters">
            <motion.div 
              className="filter selected" 
              whileHover={{ scale: 1.05 }}
            >
              <p>Nouveau</p>
              <div className="border_bottom"></div>
            </motion.div>
          </div>
        </div>
        
        <Suspense fallback={<LoadingFallback />}>
          <CardGrid 
            items={voyagenear} 
            CardComponent={LazyTravelcard} 
            isVarient={true} 
            propName="voyage"
          />
        </Suspense>

        <div className="button">
          <motion.button 
            onClick={() => { window.location.href = '/voyage' }} 
            whileHover={{ scale: 1.1 }}
          >
            Voir Plus
          </motion.button>
        </div>
      </motion.div>

      <motion.div className="p2" variants={fadeIn}>
        <div className="left_side">
          <motion.p className="title" variants={fadeIn}>
            à propos de l'agence ProTour
          </motion.p>
          <motion.p className="text" variants={fadeIn}>
            PROTOUR est une agence de voyages & de tourisme crée en 2023, dont
            le siège est situé au niveau de la wilaya de Setif.
          </motion.p>
          <motion.button 
            style={{width:"140px"}} 
            onClick={() => { window.location.href = '/us' }} 
            whileHover={{ scale: 1.1 }}
          >
            Plus sur ProTour
          </motion.button>
        </div>
        <motion.div
          className="right_side"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="logo">
            <motion.img src={logowhiteIcon} alt="logo" loading="lazy" />
          </div>
          <div className="logo2">
            <motion.img src={logo} alt="logo" loading="lazy" />
          </div>
        </motion.div>
      </motion.div>

      <motion.div className="p1" variants={fadeIn}>
        <div className="header">
          <div className="title">
            <motion.p variants={fadeIn}>
              Hôtels Partenaires les mieux notés
            </motion.p>
          </div>
          <motion.div 
            className="redirect" 
            whileHover={{ x: 10 }}
          >
            <motion.p 
              variants={fadeIn} 
              onClick={() => { window.location.href = '/hotels' }}
            >
              Voir tout
            </motion.p>
            <motion.img
              src={longrightarrowIcon}
              alt="voir tous"
              loading="lazy"
            />
          </motion.div>
        </div>
        
        <Suspense fallback={<LoadingFallback />}>
          <CardGrid 
            items={hotels} 
            CardComponent={LazyHotelcard} 
            isVarient={false} 
            propName="hotel"
            keyProp="code"
          />
        </Suspense>
      </motion.div>

      <Suspense fallback={<LoadingFallback />}>
        <Partners />
        <Serviceslist />
        <Footer />
      </Suspense>
    </motion.div>
  );
}