import React, { useState, useRef, useEffect } from "react";
import { editIcon, magicpenIcon } from "../../assets/images/icons";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Topbar from "../../components/Topbar/Topbar";
import "./voyageorg.css";
import Presentation from "../../assets/Forms/Presentation/Presentation";
import Dates from "../../assets/Forms/Dates/Dates";
import Excursions from "../../assets/Forms/Excursion/Excursion";
import Description from "../../assets/Forms/Description/Description";
import Tarif from "../../assets/Forms/Tarifs/Tarif";
import API from "../../API";
import { useParams } from "react-router-dom";

import hotelIcon from "../../assets/images/icons/hotel.png";
import dayAndnight from "../../assets/images/icons/dan.png";
import plane from "../../assets/images/icons/plane.png";
import presentationIcon from "../../assets/images/images/presentation.png";
import datesIcon from "../../assets/images/images/calendar.png";
import excursionsIcon from "../../assets/images/images/excursion.png";
import descriptionIcon from "../../assets/images/images/description.png";
import tarifIcon from "../../assets/images/images/tarif.png";

export default function Voyageorg() {
  const [view, setView] = useState(0); // Set default view to 0 to activate "Présentation" tab
  const [voyageData, setVoyageData] = useState(null);
  const profileimgRef = useRef(null);
  const { code } = useParams(); // Extract the "code" parameter from the URL

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const response = await API.get(`/voyage-organize/item/${code}`);
        
        setVoyageData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (code) {
      fetchData();
    }
  }, [code]);

  const tabs = [
    { name: "Présentation", icon: presentationIcon },
    { name: "Dates disponibles", icon: datesIcon },
    { name: "Les excursions", icon: excursionsIcon },
    { name: "Description de voyage", icon: descriptionIcon },
    ...(voyageData?.availability
      ? [{ name: "Tarifs", icon: tarifIcon }]
      : []), // Include "Tarifs" tab only if availability is true
  ];

  return (
    <div className="page-voyageorg" style={{ backgroundColor: "#F8FCFF" }}>
      <Topbar />
      <Navbar />
      <div className="voyageorg-content">
        <div className="forehead">
          <div className="forehead-content">
            <p className="breadcrumb">
              <a href="/">Acceuil</a>
              <span className="breadcrumb-separator">&gt;</span>
              <a href="/voyage-organize">Voyage Organize</a>
              <span className="breadcrumb-separator">&gt;</span>
              <span>{voyageData?.title}</span>
            </p>

            <div className="forehead-title">
              <h2>{voyageData?.title}</h2>
              <div
                className={`availability-badge ${
                  voyageData?.availability ? "available" : "not-available"
                }`}
              >
                {voyageData?.availability ? "Available" : "Not Available"}
              </div>
            </div>
            <div className="forehead-rt">
              <div className="forehead-details">
                <div className="forehead-detail">
                  <img src={dayAndnight} height="30px" width="30px" />
                  <p>
                    {`${Math.floor(
                      (new Date(voyageData?.departures[0].end) -
                        new Date(voyageData?.departures[0].start)) /
                        (1000 * 60 * 60 * 24)
                    )} days / ${Math.floor(
                      (new Date(voyageData?.departures[0].end) -
                        new Date(voyageData?.departures[0].start)) /
                        (1000 * 60 * 60 * 24)
                    ) - 1} nights`}
                  </p>
                </div>
                <div className="forehead-detail">
                  <img src={plane} height="30px" width="30px" />
                  <p>{voyageData?.airline}</p>
                </div>
                <div className="forehead-detail">
                  <img src={hotelIcon} height="30px" width="30px" />
                  <p>{voyageData?.property?.name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${view === index ? "selected" : ""}`}
              onClick={() => setView(index)}
            >
              <img
                src={tab.icon}
                alt={`${tab.name} icon`}
                className="tab-icon"
              />
              <p style={{ marginBottom: "30px", marginTop: "10px" }}>
                {tab.name}
              </p>
            </div>
          ))}
        </div>
        <div className="tab-content">
          {view === 0 && voyageData && (
            <Presentation voyageData={voyageData} />
          )}
          {view === 1 && voyageData && (
            <Dates availableDates={voyageData.departures} />
          )}
          {view === 2 && voyageData && (
            <Excursions excursions={voyageData.excursions} />
          )}
          {view === 3 && voyageData && (
            <Description description={voyageData.description} />
          )}
          {voyageData?.availability && view === 4 && voyageData && (
            <Tarif
              places={voyageData.places}
              departures={voyageData.departures}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
