import { locationIcon2, newtagIcon } from "../../assets/images/icons";
import "./carouselcard.css";
import React from "react";
import { API_URL_IMAGE } from "../../constants";

export default function Carouselcard({ title, location, duration, price, image,code }) {
  return (
    <div className="Carouselcard">
      <div
  className="upper_part"
  style={{ backgroundImage: `url(${API_URL_IMAGE[process.env.NODE_ENV]}${image.replace(/\\/g, '/')})` }}

>

        <p className="title">{title}</p>


      </div>
      <div className="lower_part">
        <div className="informations">
          <div className="row">
            <div className="titlediv">
              <img src={locationIcon2} alt="location icon" /> 
              <p>{location}</p>
            </div>
            {duration && <p className="duration">{duration}</p>}
          </div>
          <div className="row">
            <p className="title">À PARTIR DE</p>
            <p>
              <span className="black_text">
                {price} <strong> DA / Nuit</strong>
              </span>
            </p>
          </div>
        </div>
        <button className="voyage_card_button" onClick={()=>{ window.location.href =`/voyage-org/${code}`}}><p>Voir l’offre</p></button>
      </div>
    </div>
  );
}
