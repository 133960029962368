import "./visa.css";
import Select, { components } from "react-select";
import Topbar from "../../components/Topbar/Topbar";
import React, { useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { calendar2Icon } from "../../assets/images/icons";
import API from '../../API'
import { ClipLoader } from "react-spinners"; 

import Modal from 'react-modal'; // Make sure to install this package
import { X } from 'lucide-react'; // Optional: for close icon


import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const visaTypes = [
  { value: "TOURIST", label: "Tourist Visa" },
  { value: "BUSINESS", label: "Business Visa" },
  { value: "STUDENT", label: "Student Visa" },
  { value: "WORK", label: "Work Visa" },
];

const countries = [
  { value: "algeria", label: "Algeria" },
  { value: "afghanistan", label: "Afghanistan" },
  { value: "albania", label: "Albania" },

  { value: "andorra", label: "Andorra" },
  { value: "angola", label: "Angola" },
  { value: "argentina", label: "Argentina" },
  { value: "armenia", label: "Armenia" },
  { value: "australia", label: "Australia" },
  { value: "austria", label: "Austria" },
  { value: "azerbaijan", label: "Azerbaijan" },
  { value: "bahamas", label: "Bahamas" },
  { value: "bahrain", label: "Bahrain" },
  { value: "bangladesh", label: "Bangladesh" },
  { value: "barbados", label: "Barbados" },
  { value: "belarus", label: "Belarus" },
  { value: "belgium", label: "Belgium" },
  { value: "belize", label: "Belize" },
  { value: "benin", label: "Benin" },
  { value: "bhutan", label: "Bhutan" },
  { value: "bolivia", label: "Bolivia" },
  { value: "bosnia_and_herzegovina", label: "Bosnia and Herzegovina" },
  { value: "botswana", label: "Botswana" },
  { value: "brazil", label: "Brazil" },
  { value: "brunei", label: "Brunei" },
  { value: "bulgaria", label: "Bulgaria" },
  { value: "burkina_faso", label: "Burkina Faso" },
  { value: "burundi", label: "Burundi" },
  { value: "cambodia", label: "Cambodia" },
  { value: "cameroon", label: "Cameroon" },
  { value: "canada", label: "Canada" },
  { value: "cape_verde", label: "Cape Verde" },
  { value: "chad", label: "Chad" },
  { value: "chile", label: "Chile" },
  { value: "china", label: "China" },
  { value: "colombia", label: "Colombia" },
  { value: "comoros", label: "Comoros" },
  { value: "congo", label: "Congo" },
  { value: "costa_rica", label: "Costa Rica" },
  { value: "croatia", label: "Croatia" },
  { value: "cuba", label: "Cuba" },
  { value: "cyprus", label: "Cyprus" },
  { value: "czech_republic", label: "Czech Republic" },
  { value: "denmark", label: "Denmark" },
  { value: "djibouti", label: "Djibouti" },
  { value: "dominica", label: "Dominica" },
  { value: "dominican_republic", label: "Dominican Republic" },
  { value: "ecuador", label: "Ecuador" },
  { value: "egypt", label: "Egypt" },
  { value: "el_salvador", label: "El Salvador" },
  { value: "equatorial_guinea", label: "Equatorial Guinea" },
  { value: "eritrea", label: "Eritrea" },
  { value: "estonia", label: "Estonia" },
  { value: "eswatini", label: "Eswatini" },
  { value: "ethiopia", label: "Ethiopia" },
  { value: "fiji", label: "Fiji" },
  { value: "finland", label: "Finland" },
  { value: "france", label: "France" },
  { value: "gabon", label: "Gabon" },
  { value: "gambia", label: "Gambia" },
  { value: "georgia", label: "Georgia" },
  { value: "germany", label: "Germany" },
  { value: "ghana", label: "Ghana" },
  { value: "greece", label: "Greece" },
  { value: "grenada", label: "Grenada" },
  { value: "guatemala", label: "Guatemala" },
  { value: "guinea", label: "Guinea" },
  { value: "guyana", label: "Guyana" },
  { value: "haiti", label: "Haiti" },
  { value: "honduras", label: "Honduras" },
  { value: "hungary", label: "Hungary" },
  { value: "iceland", label: "Iceland" },
  { value: "india", label: "India" },
  { value: "indonesia", label: "Indonesia" },
  { value: "iran", label: "Iran" },
  { value: "iraq", label: "Iraq" },
  { value: "ireland", label: "Ireland" },
  { value: "italy", label: "Italy" },
  { value: "ivory_coast", label: "Ivory Coast" },
  { value: "jamaica", label: "Jamaica" },
  { value: "japan", label: "Japan" },
  { value: "jordan", label: "Jordan" },
  { value: "kazakhstan", label: "Kazakhstan" },
  { value: "kenya", label: "Kenya" },
  { value: "kiribati", label: "Kiribati" },
  { value: "kuwait", label: "Kuwait" },
  { value: "kyrgyzstan", label: "Kyrgyzstan" },
  { value: "laos", label: "Laos" },
  { value: "latvia", label: "Latvia" },
  { value: "lebanon", label: "Lebanon" },
  { value: "lesotho", label: "Lesotho" },
  { value: "liberia", label: "Liberia" },
  { value: "libya", label: "Libya" },
  { value: "liechtenstein", label: "Liechtenstein" },
  { value: "lithuania", label: "Lithuania" },
  { value: "luxembourg", label: "Luxembourg" },
  { value: "madagascar", label: "Madagascar" },
  { value: "malawi", label: "Malawi" },
  { value: "malaysia", label: "Malaysia" },
  { value: "maldives", label: "Maldives" },
  { value: "mali", label: "Mali" },
  { value: "malta", label: "Malta" },
  { value: "mauritania", label: "Mauritania" },
  { value: "mauritius", label: "Mauritius" },
  { value: "mexico", label: "Mexico" },
  { value: "monaco", label: "Monaco" },
  { value: "mongolia", label: "Mongolia" },
  { value: "montenegro", label: "Montenegro" },
  { value: "morocco", label: "Morocco" },
  { value: "mozambique", label: "Mozambique" },
  { value: "myanmar", label: "Myanmar" },
  { value: "namibia", label: "Namibia" },
  { value: "nepal", label: "Nepal" },
  { value: "netherlands", label: "Netherlands" },
  { value: "new_zealand", label: "New Zealand" },
  { value: "nigeria", label: "Nigeria" },
  { value: "norway", label: "Norway" },
  { value: "oman", label: "Oman" },
  { value: "pakistan", label: "Pakistan" },
  { value: "panama", label: "Panama" },
  { value: "peru", label: "Peru" },
  { value: "philippines", label: "Philippines" },
  { value: "poland", label: "Poland" },
  { value: "portugal", label: "Portugal" },
  { value: "qatar", label: "Qatar" },
  { value: "romania", label: "Romania" },
  { value: "russia", label: "Russia" },
  { value: "rwanda", label: "Rwanda" },
  { value: "saudi_arabia", label: "Saudi Arabia" },
  { value: "senegal", label: "Senegal" },
  { value: "serbia", label: "Serbia" },
  { value: "seychelles", label: "Seychelles" },
  { value: "singapore", label: "Singapore" },
  { value: "slovakia", label: "Slovakia" },
  { value: "slovenia", label: "Slovenia" },
  { value: "somalia", label: "Somalia" },
  { value: "south_africa", label: "South Africa" },
  { value: "south_korea", label: "South Korea" },
  { value: "spain", label: "Spain" },
  { value: "sri_lanka", label: "Sri Lanka" },
  { value: "sudan", label: "Sudan" },
  { value: "sweden", label: "Sweden" },
  { value: "switzerland", label: "Switzerland" },
  { value: "syria", label: "Syria" },
  { value: "taiwan", label: "Taiwan" },
  { value: "tajikistan", label: "Tajikistan" },
  { value: "tanzania", label: "Tanzania" },
  { value: "thailand", label: "Thailand" },
  { value: "togo", label: "Togo" },
  { value: "tunisia", label: "Tunisia" },
  { value: "turkey", label: "Turkey" },
  { value: "uganda", label: "Uganda" },
  { value: "ukraine", label: "Ukraine" },
  { value: "united_arab_emirates", label: "United Arab Emirates" },
  { value: "united_kingdom", label: "United Kingdom" },
  { value: "united_states", label: "United States" },
  { value: "uruguay", label: "Uruguay" },
  { value: "uzbekistan", label: "Uzbekistan" },
  { value: "vanuatu", label: "Vanuatu" },
  { value: "venezuela", label: "Venezuela" },
  { value: "vietnam", label: "Vietnam" },
  { value: "yemen", label: "Yemen" },
  { value: "zambia", label: "Zambia" },
  { value: "zimbabwe", label: "Zimbabwe" },
];


const documentTypes = [
  { value: "PASSPORT", label: "Passport" },
];



const nationalities = [
  { value: "algerian", label: "Algerian" },
  { value: "afghan", label: "Afghan" },
  { value: "albanian", label: "Albanian" },

  { value: "andorran", label: "Andorran" },
  { value: "angolan", label: "Angolan" },
  { value: "argentine", label: "Argentine" },
  { value: "armenian", label: "Armenian" },
  { value: "australian", label: "Australian" },
  { value: "austrian", label: "Austrian" },
  { value: "azerbaijani", label: "Azerbaijani" },
  { value: "bahamian", label: "Bahamian" },
  { value: "bahraini", label: "Bahraini" },
  { value: "bangladeshi", label: "Bangladeshi" },
  { value: "barbadian", label: "Barbadian" },
  { value: "belarusian", label: "Belarusian" },
  { value: "belgian", label: "Belgian" },
  { value: "belizean", label: "Belizean" },
  { value: "beninese", label: "Beninese" },
  { value: "bhutanese", label: "Bhutanese" },
  { value: "bolivian", label: "Bolivian" },
  { value: "bosnian", label: "Bosnian" },
  { value: "botswanan", label: "Botswanan" },
  { value: "brazilian", label: "Brazilian" },
  { value: "bruneian", label: "Bruneian" },
  { value: "bulgarian", label: "Bulgarian" },
  { value: "burkinabe", label: "Burkinabé" },
  { value: "burundian", label: "Burundian" },
  { value: "cambodian", label: "Cambodian" },
  { value: "cameroonian", label: "Cameroonian" },
  { value: "canadian", label: "Canadian" },
  { value: "cape_verdean", label: "Cape Verdean" },
  { value: "chadian", label: "Chadian" },
  { value: "chilean", label: "Chilean" },
  { value: "chinese", label: "Chinese" },
  { value: "colombian", label: "Colombian" },
  { value: "comoran", label: "Comoran" },
  { value: "congolese", label: "Congolese" },
  { value: "costa_rican", label: "Costa Rican" },
  { value: "croatian", label: "Croatian" },
  { value: "cuban", label: "Cuban" },
  { value: "cypriot", label: "Cypriot" },
  { value: "czech", label: "Czech" },
  { value: "danish", label: "Danish" },
  { value: "djiboutian", label: "Djiboutian" },
  { value: "dominican", label: "Dominican" },
  { value: "ecuadorian", label: "Ecuadorian" },
  { value: "egyptian", label: "Egyptian" },
  { value: "salvadoran", label: "Salvadoran" },
  { value: "equatorial_guinean", label: "Equatorial Guinean" },
  { value: "eritrean", label: "Eritrean" },
  { value: "estonian", label: "Estonian" },
  { value: "eswatini", label: "Eswatini" },
  { value: "ethiopian", label: "Ethiopian" },
  { value: "fijian", label: "Fijian" },
  { value: "finnish", label: "Finnish" },
  { value: "french", label: "French" },
  { value: "gabonese", label: "Gabonese" },
  { value: "gambian", label: "Gambian" },
  { value: "georgian", label: "Georgian" },
  { value: "german", label: "German" },
  { value: "ghanaian", label: "Ghanaian" },
  { value: "greek", label: "Greek" },
  { value: "grenadian", label: "Grenadian" },
  { value: "guatemalan", label: "Guatemalan" },
  { value: "guinean", label: "Guinean" },
  { value: "guyanese", label: "Guyanese" },
  { value: "haitian", label: "Haitian" },
  { value: "honduran", label: "Honduran" },
  { value: "hungarian", label: "Hungarian" },
  { value: "icelandic", label: "Icelandic" },
  { value: "indian", label: "Indian" },
  { value: "indonesian", label: "Indonesian" },
  { value: "iranian", label: "Iranian" },
  { value: "iraqi", label: "Iraqi" },
  { value: "irish", label: "Irish" },
  { value: "Palestinian", label: "Palestinian" },
  { value: "italian", label: "Italian" },
  { value: "ivorian", label: "Ivorian" },
  { value: "jamaican", label: "Jamaican" },
  { value: "japanese", label: "Japanese" },
  { value: "jordanian", label: "Jordanian" },
  { value: "kazakh", label: "Kazakh" },
  { value: "kenyan", label: "Kenyan" },
  { value: "kiribati", label: "Kiribati" },
  { value: "kuwaiti", label: "Kuwaiti" },
  { value: "kyrgyz", label: "Kyrgyz" },
  { value: "laotian", label: "Laotian" },
  { value: "latvian", label: "Latvian" },
  { value: "lebanese", label: "Lebanese" },
  { value: "lesotho", label: "Lesotho" },
  { value: "liberian", label: "Liberian" },
  { value: "libyan", label: "Libyan" },
  { value: "liechtensteiner", label: "Liechtensteiner" },
  { value: "lithuanian", label: "Lithuanian" },
  { value: "luxembourgish", label: "Luxembourgish" },
  { value: "madagascan", label: "Madagascan" },
  { value: "malawian", label: "Malawian" },
  { value: "malaysian", label: "Malaysian" },
  { value: "maldivian", label: "Maldivian" },
  { value: "malian", label: "Malian" },
  { value: "maltese", label: "Maltese" },
  { value: "mauritanian", label: "Mauritanian" },
  { value: "mauritian", label: "Mauritian" },
  { value: "mexican", label: "Mexican" },
  { value: "monacan", label: "Monacan" },
  { value: "mongolian", label: "Mongolian" },
  { value: "montenegrin", label: "Montenegrin" },
  { value: "moroccan", label: "Moroccan" },
  { value: "mozambican", label: "Mozambican" },
  { value: "myanmarese", label: "Myanmarese" },
  { value: "namibian", label: "Namibian" },
  { value: "nepalese", label: "Nepalese" },
  { value: "dutch", label: "Dutch" },
  { value: "new_zealander", label: "New Zealander" },
  { value: "nigerian", label: "Nigerian" },
  { value: "norwegian", label: "Norwegian" },
  { value: "omani", label: "Omani" },
  { value: "pakistani", label: "Pakistani" },
  { value: "panamanian", label: "Panamanian" },
  { value: "peruvian", label: "Peruvian" },
  { value: "philippine", label: "Philippine" },
  { value: "polish", label: "Polish" },
  { value: "portuguese", label: "Portuguese" },
  { value: "qatari", label: "Qatari" },
  { value: "romanian", label: "Romanian" },
  { value: "russian", label: "Russian" },
  { value: "rwandan", label: "Rwandan" },
  { value: "saudi", label: "Saudi" },
  { value: "senegalese", label: "Senegalese" },
  { value: "serbian", label: "Serbian" },
  { value: "seychellois", label: "Seychellois" },
  { value: "singaporean", label: "Singaporean" },
  { value: "slovak", label: "Slovak" },
  { value: "slovenian", label: "Slovenian" },
  { value: "somali", label: "Somali" },
  { value: "south_african", label: "South African" },
  { value: "south_korean", label: "South Korean" },
  { value: "spanish", label: "Spanish" },
  { value: "sri_lankan", label: "Sri Lankan" },
  { value: "sudanese", label: "Sudanese" },
  { value: "swedish", label: "Swedish" },
  { value: "swiss", label: "Swiss" },
  { value: "syrian", label: "Syrian" },
  { value: "taiwanese", label: "Taiwanese" },
  { value: "tajik", label: "Tajik" },
  { value: "tanzanian", label: "Tanzanian" },
  { value: "thai", label: "Thai" },
  { value: "togolese", label: "Togolese" },
  { value: "tunisian", label: "Tunisian" },
  { value: "turkish", label: "Turkish" },
  { value: "ugandan", label: "Ugandan" },
  { value: "ukrainian", label: "Ukrainian" },
  { value: "emirati", label: "Emirati" },
  { value: "british", label: "British" },
  { value: "american", label: "American" },
  { value: "uruguayan", label: "Uruguayan" },
  { value: "uzbek", label: "Uzbek" },
  { value: "vanuatu", label: "Vanuatuan" },
  { value: "venezuelan", label: "Venezuelan" },
  { value: "vietnamese", label: "Vietnamese" },
  { value: "yemeni", label: "Yemeni" },
  { value: "zambian", label: "Zambian" },
  { value: "zimbabwean", label: "Zimbabwean" },
];



const PrivacyTermsModal = ({ isOpen, onClose, onAccept }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
          maxHeight: '80vh',
          overflow: 'auto',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 1000
        }
      }}
      contentLabel="Privacy and Terms Policy"
    >
      <div className="modal-header">
        
        <h2 className="text-xl font-bold mb-4">Politique de Confidentialité et Conditions d'Utilisation</h2>
       
      </div>
      
      <div className="modal-content">
        <h3 className="font-semibold mb-2">1. Collecte des Informations Personnelles</h3>
        <p className="mb-4">
          Nous collectons des informations personnelles que vous nous fournissez directement, 
          telles que votre nom, adresse e-mail, et informations de passeport lors de votre 
          demande de visa.
        </p>
        
        <h3 className="font-semibold mb-2">2. Utilisation des Informations</h3>
        <p className="mb-4">
          Vos informations sont utilisées uniquement dans le cadre du traitement de votre 
          demande de visa et ne seront jamais partagées avec des tiers sans votre consentement explicite.
        </p>
        
        <h3 className="font-semibold mb-2">3. Protection des Données</h3>
        <p className="mb-4">
          Nous mettons en œuvre des mesures de sécurité avancées pour protéger vos 
          informations personnelles contre tout accès non autorisé, modification ou divulgation.
        </p>
        
        <h3 className="font-semibold mb-2">4. Délai de Traitement</h3>
        <p className="mb-4">
          Tout délai de traitement est susceptible de varier en fonction de la complexité 
          de votre demande et des vérifications nécessaires.
        </p>
        
        <h3 className="font-semibold mb-2">5. Erreurs et Annulations</h3>
        <p className="mb-4">
          Toute erreur dans les informations de visa doit être immédiatement signalée 
          et peut entraîner l'annulation de la demande.
        </p>
      </div>
      
      <div className="modal-footer mt-4 flex justify-end">
        <button 
          onClick={onAccept}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
          style={{background:"orange",padding:"15px",fontFamily:"sans-serif",fontSize:"15px",fontWeight:"bold",border:"none",borderRadius:"4px",cursor:"pointer"}}
        >
          J'ai lu les conditions
        </button>
      </div>
    </Modal>
  );
};



const ApplicantForm = ({ index, onChange, values }) => {
  return (
    <div className="applicant-section">
      <h3 className="subtitle">Applicant {index + 1}</h3>
      <div className="biggy">
        <div className="two_inputs">
          <label>
            <p className="input_label">nom</p>
            <input 
              type="text" 
              placeholder="Mohamed" 
              name="name" 
              value={values.name || ''} 
              required 
              onChange={(e) => onChange(index, 'name', e.target.value)} 
            />
          </label>
          <label>
            <p className="input_label">surnom</p>
            <input 
              type="text" 
              placeholder="El Amine" 
              name="surnom" 
              value={values.secondname || ''} 
              required 
              onChange={(e) => onChange(index, 'secondname', e.target.value)}
            />
          </label>
        </div>
        <div className="two_inputs">
          <label>
            <p className="input_label">Lieu de naissance</p>
            <input 
              type="text" 
              placeholder="Batna" 
              name="location" 
              value={values.birthplace || ''} 
              required 
              onChange={(e) => onChange(index, 'birthplace', e.target.value)}
            />
          </label>
          <div className="input var3">
            <img src={calendar2Icon} alt="calendar" />
            <DatePicker
              selected={values.birthdate ? new Date(values.birthdate) : null}
              onChange={(date) => onChange(index, 'birthdate', date.toISOString().split("T")[0])}
              placeholderText="Date de naissance"
              dateFormat="yyyy-MM-dd"
              className="biggo"
              required
            />
          </div>
        </div>
        <div className="two_inputs">
          <label>
            <p className="input_label">No.Passport</p>
            <input 
              type="text" 
              placeholder="0123456" 
              name="passport" 
              value={values.nopassport || ''} 
              required 
              onChange={(e) => onChange(index, 'nopassport', e.target.value)}
            />
          </label>
          <div className="input var3">
            <img src={calendar2Icon} alt="calendar" />
            <DatePicker
              selected={values.expirationdate ? new Date(values.expirationdate) : null}
              onChange={(date) => onChange(index, 'expirationdate', date.toISOString().split("T")[0])}
              placeholderText="Date debut d'expiration"
              dateFormat="yyyy-MM-dd"
              className="biggo"
              required
            />
          </div>
        </div>
        <div className="two_inputs">
          <label>
            <p className="input_label">Email addresse</p>
            <input 
              type="text" 
              placeholder="mouhamed.Elamine@example.com" 
              name="email" 
              value={values.emailadress || ''} 
              required 
              onChange={(e) => onChange(index, 'emailadress', e.target.value)}
            />
          </label>
        </div>
        
        <div className="documents">
          <p className="title">Document a telecharger - Applicant {index + 1}</p>
          <div className="container">
            <div className="right_part">
              <div className="el" style={{marginBottom:'20px'}}>
                <p className="text">Scan Passport</p>
                <button style={{marginLeft:"20px"}} onClick={() => document.getElementById(`file1-${index}`).click()}>
                  <p>Browse...</p>
                </button>
                <input
                  id={`file1-${index}`}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => onChange(index, 'file1', e.target.files[0])}
                />
                <p className="file_name">
                  {values.file1 ? values.file1.name : <span className="not_selected">Aucun fichier sélectionné</span>}
                </p>
              </div>
              
              <div className="el" style={{marginBottom:'20px'}}>
                <p className="text">Scan Photo ( Fond blanc)</p>
                <button style={{marginLeft:"20px"}} onClick={() => document.getElementById(`file2-${index}`).click()}>
                  <p>Browse...</p>
                </button>
                <input
                  id={`file2-${index}`}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => onChange(index, 'file2', e.target.files[0])}
                />
                <p className="file_name">
                  {values.file2 ? values.file2.name : <span className="not_selected">Aucun fichier sélectionné</span>}
                </p>
              </div>
              
              <div className="el" style={{marginBottom:'20px'}}>
                <p className="text">Scan act de naissance</p>
                <button style={{marginLeft:"20px"}} onClick={() => document.getElementById(`file3-${index}`).click()}>
                  <p>Browse...</p>
                </button>
                <input
                  id={`file3-${index}`}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => onChange(index, 'file3', e.target.files[0])}
                />
                <p className="file_name">
                  {values.file3 ? values.file3.name : <span className="not_selected">Aucun fichier sélectionné</span>}
                </p>
              </div>
            </div>
            <div className="left_part">
              <div className="text">
                <p>
                  La taille de chaque fichier no doit pas depasser 20 MB. type
                  de fichier soit "JPEG", "PNG" ,"PDF"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};









export default function Visa() {


  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const openPrivacyModal = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setIsPrivacyModalOpen(true);
  };

  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
  };

  const handlePrivacyAccept = () => {
    setPrivacyAccepted(true);
    setAcceptedTerms(true);
    closePrivacyModal();
  };


  const [errormessage,setErrormessage]= useState('')


  const [loadingMessage,setLoadingMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const [nopersonne,setNopersonne]= useState(1)
  const [visatype,setVisatype]= useState(visaTypes[0])
  const [startDate, setStartDate] = useState('');
  const [nationality, setNationality] = useState(nationalities[0]);
  const [documentTypev, setDocumentType] = useState(documentTypes[0]);
  const [paymentCountry, setPaymentCountry] = useState(countries[0]);
  const [acceptedTerms,setAcceptedTerms]= useState(false)



  const [applicants, setApplicants] = useState([{
    name: '',
    secondname: '',
    birthplace: '',
    birthdate: '',
    nopassport: '',
    expirationdate: '',
    emailadress: '',
    file1: null,
    file2: null,
    file3: null
  }]);

  const handleApplicantChange = (index, field, value) => {
    const newApplicants = [...applicants];
    newApplicants[index] = {
      ...newApplicants[index],
      [field]: value
    };
    setApplicants(newApplicants);
  };

  
  const handleNumberOfPersonsChange = (selectedOption) => {
    const newNumber = selectedOption.value;
    setNopersonne(newNumber);

    const updatedApplicants = applicants.slice(0, newNumber);
    while (updatedApplicants.length < newNumber) {
      updatedApplicants.push({
        name: '',
        secondname: '',
        birthplace: '',
        birthdate: '',
        nopassport: '',
        expirationdate: '',
        emailadress: '',
        file1: null,
        file2: null,
        file3: null,
      });
    }
    setApplicants(updatedApplicants);
  };




  const notifySuccess = (message) => 
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const notifyError = (message) => 
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const notifyWarning = (message) => 
    toast.warn(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
      return false;
    }
    const date = new Date(dateString);
    const [year, month, day] = dateString.split("-");
    return (
      date &&
      date.getFullYear() === parseInt(year, 10) &&
      date.getMonth() + 1 === parseInt(month, 10) &&
      date.getDate() === parseInt(day, 10)
    );
  };

  const handleSubmit = async () => {


   
    const token = localStorage.getItem('protoken');
    if(!token){
      notifyError("You need to login first");
      return;
    }





  
    if (!isValidDate(startDate)) {
      setErrormessage("Date de début de voyage invalide. Format attendu : YYYY-MM-DD.");
      setLoading(false);
      return;
    }

  


    if(!acceptedTerms){

      setErrormessage("You need to accept terms.")
      setLoading(false);
      return;
    }

    for (let i = 0; i < applicants.length; i++) {
      const applicant = applicants[i];
      if (!applicant.name.trim()) {
        notifyError(`Applicant ${i + 1}: Name is required`);
        return;
      }
      if (!applicant.secondname.trim()) {
        notifyError(`Applicant ${i + 1}: Second name is required`);
        return;
      }
      if (!applicant.birthplace.trim()) {
        notifyError(`Applicant ${i + 1}: Birthplace is required`);
        return;
      }
      if (!isValidDate(applicant.birthdate)) {
        notifyError(`Applicant ${i + 1}: Invalid birthdate. Format: YYYY-MM-DD`);
        return;
      }
      if (!applicant.nopassport.trim()) {
        notifyError(`Applicant ${i + 1}: Passport number is required`);
        return;
      }
      if (!isValidDate(applicant.expirationdate)) {
        notifyError(`Applicant ${i + 1}: Invalid passport expiration date. Format: YYYY-MM-DD`);
        return;
      }
      if (!applicant.emailadress.trim()) {
        notifyError(`Applicant ${i + 1}: Email address is required`);
        return;
      }
      if (!/\S+@\S+\.\S+/.test(applicant.emailadress)) {
        notifyError(`Applicant ${i + 1}: Invalid email address`);
        return;
      }
    }



  
    const formData = new FormData();
    formData.append("numPersons", nopersonne);
    formData.append("visaType", visatype.value);
    formData.append("startDate", startDate);
    formData.append("nationality", nationality.value);
    formData.append("travelDocument", documentTypev.value);
    formData.append("country", paymentCountry.value);
    formData.append("acceptedTerms",acceptedTerms)


    applicants.forEach((applicant, index) => {
      formData.append(`applicants[${index}][name]`, applicant.name);
      formData.append(`applicants[${index}][surname]`, applicant.secondname);
      formData.append(`applicants[${index}][birthPlace]`, applicant.birthplace);
      formData.append(`applicants[${index}][birthDate]`, applicant.birthdate);
      formData.append(`applicants[${index}][passportNumber]`, applicant.nopassport);
      formData.append(`applicants[${index}][expirationDate]`, applicant.expirationdate);
      formData.append(`applicants[${index}][email]`, applicant.emailadress);
      
      if (applicant.file1) formData.append(`applicants[${index}][passportScan]`, applicant.file1);
      if (applicant.file2) formData.append(`applicants[${index}][photoScan]`, applicant.file2);
      if (applicant.file3) formData.append(`applicants[${index}][birthCertScan]`, applicant.file3);
    });

    const userId = localStorage.getItem('userId'); // Make sure the key is correct
    formData.append("userId", userId); 
  

    console.log(formData)
   
    
    try {
      
      const token = localStorage.getItem('protoken');
      if(!token){
        notifyError("You need to login first");
        return;
      }

      const response = await API.post('/visas', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, 
        },
      });
      console.log(response)
      setLoadingMessage('Submitting your visa application...')
      
      notifySuccess('Submitting your visa application...');

      setTimeout(()=>{
      
      },2000)
      
      

    } catch (error) {
      setLoadingMessage('You need to login first')
      setTimeout(()=>{
       
      },2000)
      console.error('Error submitting form:', error);
    }
  }
  const styles = {
    control: (provided, state) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "100%",
      height: "50px",
      
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: "0 0 0 0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "50px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown appears above all other elements
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Ensure the menu portal has a high z-index as well
    }),
  };

  const numberOptions = Array.from({length: 10}, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`
  }));

  return (
    <div className="Page_visa">


      
      <Topbar />
      <Navbar page={"visa"}/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {!loading ? (
      <div className="main">
        <p className="title">creer une demande de Visa</p>
        <div className="section1">
          <div className="mobileDO">
          <p className="title">Entrer les criteres de votre VISA</p>
          <div className="row">

            
          <label>
  <p className="input_label z">No. Personnes</p>
  <Select 
    styles={styles} 
    options={numberOptions} 
    value={{ value: nopersonne, label: `${nopersonne}` }}
    onChange={handleNumberOfPersonsChange}
    placeholder="Select number of persons"
  />
</label>


            <label>
              <p className="input_label z">Type de visa</p>
              <Select styles={styles} options={visaTypes} placeholder={""} value={visatype} onChange={setVisatype} />
            </label>
            <label>
              <p className="input_label z">Destination</p>
              <Select styles={styles} options={countries} placeholder={""} value={paymentCountry} onChange={setPaymentCountry}/>
            </label>

            <label>
              <p className="input_label z">Nationalite</p>
              <Select styles={styles} options={nationalities} placeholder={""} value={nationality} onChange={setNationality}/>
            </label>
          </div>
          <div className="row2">
            <label>
              <p className="input_label z">Document de voyage</p>
              <Select styles={styles} options={documentTypes} placeholder={""} value={documentTypev} onChange={setDocumentType}/>
            </label>
            <div className="input var2">
  <img src={calendar2Icon} alt="calendar" />
  <DatePicker
    selected={startDate ? new Date(startDate) : null} // Parse startDate to Date object
    onChange={(date) => setStartDate(date.toISOString().split("T")[0])} // Convert selected date to YYYY-MM-DD format
    placeholderText="Date debut de voyage"
    dateFormat="yyyy-MM-dd"
    className="biggo" // Add your styles here
    minDate={new Date()} // Disable dates before today
    showDisabledMonthNavigation 
    required
  />
</div>

          </div>
          </div>
        </div>
        <div className="section1" style={{ marginTop: "3rem" }}>
          <div className="mobileDO2">
          <p className="title">Entrer les criteres de votre VISA</p>
          {applicants.map((applicant, index) => (
          <div key={index} className="" style={{ marginTop: "3rem", marginBottom:"3rem" }}>
            <ApplicantForm
              index={index}
              onChange={handleApplicantChange}
              values={applicant}
            />
             {index < applicants.length - 1 && <hr style={{ margin: "2rem 0" }} />}
          </div>
        ))}
          </div>
         
        </div>
        <div
          className="section1"
          style={{ marginTop: "5rem", marginBottom: "10rem" }}
        >
          <p className="title">
            J’accepte les condditions generale de demande de VISA
          </p>
          <div className="full_width">
            {" "}
            <div className="note">
              <p>
                <span className="bluer">Important !</span> Afin de procedera
                votre demande doe visa electronique , vous devez lire
                attentivement les clauses suivantes et les accepter en desus.{" "}
              </p>
            </div>
          </div>
          <div className="conditions">
            <p>
              Les frais de visa sont prepaied
              <br />
              « Les frais sont non remboursables en cas de refus
              <br />
              « Les visa sont lobjet dapproval de limmigration
              <br />
              « ll est interdit de demander un nouveau visa et un autre visa est
              toujours valable
              <br />
              « Afin déviter le refus les documents doivent étre bien scannés
              <br />
              « Quelle est la validité des visas pour Dubai ? Les visas sont
              valables 60 jours & compter de sa date d'émission (sauf les visas
              de 48 heures et de 96 heures qui sont valables 30 jours) (selon la
              lettre dinvitation officielle ainsi que la décision consulaire) ,
              I'entrée doit étre effectuée durant cette période.
              <br />
              « Nous pouvons vous procurer cette assurance. Vous pouvez la
              commander en méme temps que votre visa. - Nous vous prions de bien
              vouloir ne pas confirmer vos billets d'avion avant I'obtention de
              votre visa. Nous ne serons pas tenus responsables des dommages,
              quels quils soient, iés & un éventuel retard de limmigration de
              Dubai dans la délivrance de votre visa
              <br />
              « Eten plus pour les mineurs : - Les scans des passeports de I'un
              des 2 parents. - Les copies des pages du livret de famille ou
              copie d'un acte de naissance
              <br />
              « Attention : Le visa est refusé si une demande a déja 6té soumise
              & fimmigration des Emirats Arabes Unis. Avant de nous transmettre
              votre dossier de visa, veuillez & bien vérifier quaucune demande
              n'a été transmise via un autre organisme sous peine de perdre vos
              frais de visa. Remarque : une demande de visa ne peut pas étre
              traitée un jour de vendredi puisque c’est un jour de repos &
              Dubai. Veuillez ne pas prendre en consideration ce jour dans le
              délai de traitement
              <br />« Tout erreur dans les visa doit étre signalée et exige l
              annulation
            </p>
          </div>
          <div className="check">
            <label>
              <input type="checkbox" onChange={(e)=>{
                 setAcceptedTerms(e.target.checked)}}/>{" "}
              <p className="text">
              Veuillez cocher pour reconnaître notre{" "}
                <a href="#" onClick={openPrivacyModal}>Politique de Confidentialité et Conditions</a>
              </p>
            </label>
          </div>
                {/* Privacy Terms Modal */}
      <PrivacyTermsModal 
        isOpen={isPrivacyModalOpen}
        onClose={closePrivacyModal}
        onAccept={handlePrivacyAccept}
      />

      {/* ... rest of the existing code ... */}
          {!errormessage ? (
  ('')
) : (
  <p style={{
    color: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    fontWeight: 'bold',
    fontSize: '14px',
    margin: '10px 0',
    padding: '10px',
    border: '1px solid red',
    borderRadius: '5px',
    backgroundColor: '#ffe6e6',
  }}>
    {errormessage}
  </p>
)}
          <div className="price_confirm">
            <p>Payement dans le bureau</p>{" "}
            <button onClick={handleSubmit}>
              <p className="button">Envoyer la demande</p>
              

            </button>
          </div>
        </div>
      </div>
      ):(
<div className="loader-container">
  <ClipLoader color={"#123abc"} loading={loading} size={70} />
  <p style={{marginLeft:'10px'}}>{loadingMessage}</p>
</div>

      )}
      <Footer />
    </div>
  );
}
