import React from "react";
import "./excursion.css";

export default function Excursions({ excursions }) {
  return (
    <div className="excursions">
      <h2>Les Excursions</h2>

      <strong>Dans ce voygae on va faire</strong>
      <ul className="excursions-list">
        {excursions.map((excursion, index) => (
          <li key={index} className="excursion-item">
            {excursion}
          </li>
        ))}
      </ul>
    </div>
  );
}
