import { editIcon } from "../../images/icons";
import Select from "react-select";
import "./comptinformations.css";
import React, { useState } from "react";
import API from "../../../API";
import Swal from "sweetalert2"; // Import SweetAlert2
import Joi from "joi"; // Import Joi

export default function Comptinformations({ userData }) {
  const [formData, setFormData] = useState({
    email: userData?.email || "",
    phone: userData?.phone || "",
    password: "",
  });



  // Joi schema for validation
  const schema = Joi.object({
    password: Joi.string().min(8).required().label('Mot de passe'),
    phone: Joi.string()
      .pattern(/^[0-9]{10}$/) // Ensures phone has exactly 10 digits
      .required()
      .label('Téléphone'),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data using Joi schema
    const { error } = schema.validate({
      password: formData.password,
      phone: formData.phone,
    });

    if (error) {
      // Show SweetAlert2 error for validation failure
      Swal.fire({
        icon: 'error',
        title: 'Erreur de validation',
        text: error.details[0].message,
        confirmButtonText: 'OK',
      });
      return; // Stop form submission if validation fails
    }

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("protoken");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const updatedData = {
        phone: formData.phone,
        hash: formData.password || undefined,
      };

      await API.patch(`/users/${userId}`, updatedData, config);

      // Success alert
      Swal.fire({
        icon: 'success',
        title: 'Mise à jour réussie!',
        text: 'Vos informations personnelles ont été mises à jour avec succès.',
        confirmButtonText: 'OK',
      }).then(() => {
        window.location.href = '/profile';
      });
    } catch (error) {
      console.error("Error updating account information:", error);

      // Error alert
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur est survenue lors de la mise à jour des informations.',
        confirmButtonText: 'OK',
      });
    }
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "105%",
      height: "50px",
      minWidth: "270px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: "0 0 0 0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "50px",
    }),
  };

  return (
    <form className="Comptinformations" onSubmit={handleSubmit}>
      <div className="title">
        <p>Informations personnelles</p>
        <img src={editIcon} alt="edit" />
      </div>
      <div className="two_inputs">
        <label className="one_input">
          <p className="input_label">Email</p>
          <input
            type="email"
            placeholder="example@domain.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
            id="riwriw"
            readOnly
          />
        </label>
        <label>
          <p className="input_label">Téléphone</p>
          <input
            type="tel"
            placeholder="+213 555 123 456"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="two_inputs">
        <label>
          <p className="input_label">Mot de passe</p>
          <input
            type="password"
            placeholder="••••••••"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="resetpsw">
        <p>
          Mot de passe oublié ? Contact the Support
        </p>
      </div>
      <button id="awtawt" type="submit">
        Update
      </button>
    </form>
  );
}
