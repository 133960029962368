import "./searchbar.css";
import React, { useState, useEffect } from "react";
import API from "../../API";

export default function Searchbar({ onSearch, initialParams = {} }) {
  const [searchParams, setSearchParams] = useState({
    location: initialParams.location || "",
    hotelCategory: initialParams.hotelCategory || "",
    priceMin: initialParams.priceMin || "",
    priceMax: initialParams.priceMax || ""
  });
  const [locationOptions, setLocationOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);

  // Fetch unique locations and titles for dropdown
  useEffect(() => {
    const fetchSearchOptions = async () => {
      try {
        const response = await API.get("/voyage-organize");
        const uniqueLocations = [
          ...new Set(
            response.data
              .map((voyage) => voyage.cite?.trim())
              .filter((cite) => cite && cite !== "")
          ),
        ];
        const uniqueTitles = [
          ...new Set(
            response.data
              .map((voyage) => voyage.title?.trim())
              .filter((title) => title && title !== "")
          ),
        ];
        setLocationOptions(uniqueLocations);
        setTitleOptions(uniqueTitles);
      } catch (error) {
        console.error("Error fetching search options:", error);
        setLocationOptions([]);
        setTitleOptions([]);
      }
    };
    fetchSearchOptions();
  }, []);

  const handleInputChange = (field, value) => {
    const updatedParams = {
      ...searchParams,
      [field]: value
    };
    
    setSearchParams(updatedParams);

    // Trigger search immediately after input change
    if (onSearch) {
      const searchData = {
        ...updatedParams,
        priceMin: updatedParams.priceMin ? Number(updatedParams.priceMin) : undefined,
        priceMax: updatedParams.priceMax ? Number(updatedParams.priceMax) : undefined
      };
      onSearch(searchData);
    }
  };

  return (
    <div className="Searchbar">
      <div className="container">
        <p className="title">Voyage organise</p>
        <div className="row">
          <p className="text">Voyage organise</p>
          
          {/* Location Input */}
          <div className="input-container">
            <input
              type="text"
              placeholder="Location"
              value={searchParams.location}
              onChange={(e) => handleInputChange("location", e.target.value)}
              list="location-options"
            />
            <datalist id="location-options">
              {locationOptions.map((loc, index) => (
                <option key={index} value={loc} />
              ))}
            </datalist>
          </div>

          {/* Hotel Category Input */}
          <div className="input-container">
            <input
              type="text"
              placeholder="Title"
              value={searchParams.hotelCategory}
              onChange={(e) => handleInputChange("hotelCategory", e.target.value)}
              list="title-options"
            />
            <datalist id="title-options">
              {titleOptions.map((title, index) => (
                <option key={index} value={title} />
              ))}
            </datalist>
          </div>

          {/* Price Min Input */}
          <div className="input-container">
            <input
              type="number"
              placeholder="Prix minimum"
              value={searchParams.priceMin}
              onChange={(e) => handleInputChange("priceMin", e.target.value)}
              min="0"
            />
          </div>

          {/* Price Max Input */}
          <div className="input-container">
            <input
              type="number"
              placeholder="Prix maximum"
              value={searchParams.priceMax}
              onChange={(e) => handleInputChange("priceMax", e.target.value)}
              min="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}