import {
  calendar2Icon,
  calendarIcon,
  locationIcon,
  minusIcon,
  peopleIcon,
  perssonesIcon,
  plusIcon,
  settingIcon,
  phonecall,
  starIcon,
} from "../../assets/images/icons";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import "./hotel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import React from "react";
import { hotelImage } from "../../assets/images/images";
import Hotelroom from "../../components/Hotelroom/Hotelroom";
import { useParams } from "react-router-dom";
import API from "../../API";
import { API_URL_IMAGE } from "../../constants";
import API2 from "../../API2";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for missing marker icons in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default function Hotel() {
  const [enfants, setEnfants] = useState(0);
  const [adults, setAdults] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const { code } = useParams(); // Extract `code` from the route params
  const [data, setData] = useState(null); // State to store fetched data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [rates, setRates] = useState(null);

  const mapRef = useRef(null); // Reference for the map

  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await API.get(`properties/one/${code}`); // Fetch data from the API
       
        setData(response.data); // Set fetched data
      } catch (err) {
        setError(err.message || "Something went wrong"); // Handle errors
      } finally {
        setLoading(false); // Turn off loading state
      }
    };

    fetchData(); // Call the async function
  }, [code]);

  useEffect(() => {
    // Only fetch rates if data exists and has an id
    if (data && data.id) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await API2.get(`rates/protour/${data.id}`);
          
          setRates(response.data.rates || []); // Provide an empty array as fallback
        } catch (err) {
          setError(err.message || "Something went wrong");
          setRates([]); // Set to empty array on error
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [data]);

  // Dynamically center the map when coordinates change
  useEffect(() => {
    if (data?.latitude && data?.longitude && mapRef.current) {
      const map = mapRef.current;
      map.setView([data.latitude, data.longitude], 13); // Center the map
    }
  }, [data?.latitude, data?.longitude]);

  const slideDownHandler = () => {
    setIsExpanded((state) => !state);
  };

  const filteredRates = rates?.filter(rate => rate.details && rate.details.length > 0);

  return (
    <div className="Page_hotel">
      <Topbar />
      <Navbar />
      <div className="main">
        <div className="p1_big_container">
          <div className="top_part">
            <div className="left_part">
              <p className="title">{data?.name}</p>
              <div className="rates">
                <img src={starIcon} alt="rates" />{" "}
                <p className="rates">
                  <span className="golden_tag">4.6</span>
                </p>
              </div>
              <p className="desc">Description</p>
              <p className="desc_text">
                {data ? data.description : "Loading..."}
              </p>

              <p className="desc">A partir</p>
              <p className="price">
                <span className="blue_tag">
                  <span className="light_tag">{data?.startprice?data?.startprice:'10 000'} </span>DA / per
                </span>
              </p>

              <div className="location">
                <img src={locationIcon} alt="location" />
                <p className="location_text">
                  {data ? `${data.location}` : "Loading location..."}
                </p>
              </div>
              <div className="location" style={{marginTop:"10px"}}>
                <img src={phonecall} alt="location" height={"15px"} width={"15px"} style={{marginRight:"10px",marginLeft:"5px"}}/>
                <p className="location_text">
                  {data ? `${data.phonenumber}` : "Loading location..."}
                </p>
              </div>
              <p className="desc">Services</p>

              <div className="services">
                {data?.services?.length > 0 ? (
                  data.services.map((service, index) => (
                    <p className="service" key={index}>
                      {service}
                    </p>
                  ))
                ) : (
                  <p className="no-service">No services available</p>
                )}
              </div>
            </div>
            <div className="right_part">
              <Swiper
                pagination={{
                  clickable: true,
                  el: ".swiper-pagination",
                  type: "bullets",
                }}
                style={{ width: "100%", height: "700px" }}
                autoplay={{ delay: 3000 }}
              >
                {data?.images?.map((image, index) => (
                  <SwiperSlide key={image.id}>
                    <div
                      className="slide_cn"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={`${API_URL_IMAGE[process.env.NODE_ENV]}${image.url}`} // Adjust base URL as needed
                        alt={`Hotel Thumbnail ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "7px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))}

                <div className="swiper-pagination" />
              </Swiper>
            </div>
          </div>
        </div>
        <h2>Rates</h2>
        <div className="p2">
        {filteredRates && filteredRates.length > 0 ? (
            filteredRates.map((rate) => <Hotelroom key={rate.id} rate={rate} />)
          ) : (
            <p>No rates with details available</p>
          )}
        </div>
        <div className="map">
          <p className="title">Sur la carte</p>
          <div className="map_location">
            <MapContainer
              ref={mapRef}
              center={data?.latitude && data?.longitude ? [data.latitude, data.longitude] : [36.1911, 5.4137]} // Default coordinates
              zoom={13}
              style={{ height: "400px", width: "100%" }}
              whenCreated={(mapInstance) => (mapRef.current = mapInstance)} // Attach map instance to ref
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {data?.latitude && data?.longitude && (
                <Marker position={[data.latitude, data.longitude]}>
                  <Popup>{data.location || "Selected Location"}</Popup>
                </Marker>
              )}
            </MapContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
