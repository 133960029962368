import { uslogo } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import "./us.css";

import React from "react";

export default function Us() {
  return (
    <div className="Page_us">
      <Topbar />
      <Navbar />
      <div className="main">
        <div className="container">
          <div className="left_part">
          <p className="title">Qui sommes-nous ?</p>
<p className="parg">
  Chez **Protour**, nous sommes bien plus qu’une simple agence de voyages : nous sommes votre partenaire privilégié pour explorer le monde avec sérénité et élégance. Spécialisés dans la gestion d’hôtels, l’organisation de voyages sur mesure, et la réservation de services variés, nous nous engageons à transformer vos rêves de voyage en expériences inoubliables. Que vous planifiiez une escapade romantique, des vacances en famille, ou un voyage d’affaires, notre équipe met tout en œuvre pour vous offrir une expérience fluide, agréable et entièrement adaptée à vos attentes.
</p>
<p className="parg">
  Depuis nos débuts, notre mission est de simplifier et d’enrichir vos déplacements, tout en vous offrant des solutions personnalisées et innovantes. Chez Protour, nous travaillons en étroite collaboration avec les meilleurs hôtels, prestataires de services et compagnies de transport pour vous proposer des options de qualité supérieure. Nous gérons pour vous les moindres détails : de la sélection des hébergements les plus confortables à l’organisation d’itinéraires sur mesure, en passant par la réservation de billets et l’obtention de visas. Chaque aspect de votre voyage est pris en charge avec professionnalisme et souci du détail.
</p>
<p className="parg">
  Nos services s’appuient sur une expertise approfondie et une passion pour le voyage. Nous comprenons que chaque client est unique, avec des besoins et des envies spécifiques. C’est pourquoi nous offrons des solutions sur mesure qui vous permettent de vivre des expériences authentiques, que ce soit pour découvrir des cultures lointaines, explorer des paysages magnifiques, ou simplement vous détendre dans un cadre luxueux. Avec Protour, vous bénéficiez de conseils avisés, de recommandations adaptées et d’un accompagnement à chaque étape de votre parcours.
</p>
<p className="parg">
  En tant qu’agence de voyage complète, nous allons au-delà de l’organisation classique. Nous vous proposons une assistance dédiée, notamment pour les démarches administratives comme les visas, les réservations spéciales, ou encore l’accès à des offres exclusives grâce à nos partenariats avec des acteurs majeurs du secteur du tourisme. Notre objectif est de faire de chaque voyage une expérience fluide, enrichissante et mémorable, tout en vous offrant un excellent rapport qualité-prix.
</p>
<p className="parg">
  Chez Protour, nous croyons fermement que voyager, c’est bien plus que visiter une destination : c’est créer des souvenirs, tisser des liens et enrichir son regard sur le monde. Nous sommes là pour faire de chaque étape de votre voyage une réussite, avec un engagement sans faille envers la satisfaction de nos clients.
</p>
<p className="parg">
  Vous rêvez d’une destination ou vous souhaitez organiser un séjour parfait ? Contactez-nous dès aujourd’hui et laissez-nous vous accompagner dans l’organisation de votre prochaine aventure. Avec Protour, vos voyages prennent une toute nouvelle dimension. Ensemble, explorons le monde !
</p>

          </div>
          <div className="right_part">
            <img src={uslogo} alt="logo"/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
