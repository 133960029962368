import React from "react";
import "./presentation.css";
import image from "../../images/images/travelcard.png";
import imagepdf from "../../images/images/pdf.png";
import locationIcon from "../../images/icons/hotel.png";
import airlineIcon from "../../images/icons/plane.png";
import { API_URL_IMAGE } from "../../../constants";

export default function Presentation({ voyageData }) {
  if (!voyageData) {
    return <p>Loading...</p>;
  }

  const {
    title,
    cite,
    street,
    airline,
    description,
    document,
    images
  } = voyageData;

  

  return (
    <div className="presentation">
      {image && <img src={`${API_URL_IMAGE[process.env.NODE_ENV]}${images?.[0].url}`} alt={title} className="presentation-image" />}
      <div className="presentation-details">
        <h2>{title}</h2>
        
        {/* Location with Icon */}
        <p className="icon-text">
          <img src={locationIcon} alt="Location Icon" className="icon" />
          {cite}, {street}
        </p>
        
        {/* Airline with Icon */}
        <p className="icon-text">
          <img src={airlineIcon} alt="Airline Icon" className="icon" />
          {airline}
        </p>

        {/* Description */}
        <p className="description"><strong>Description:</strong> {description}</p>

        {/* Document Link */}
        {document && (
          <p className="plan-de-voyage" style={{marginTop: "30px"}}>
            <strong>Plan de voyage:</strong>
            <a
              href={`${API_URL_IMAGE[process.env.NODE_ENV]}${document}`}
              target="_blank"
              rel="noopener noreferrer"
              className="document-link"
            >
              <img src={imagepdf} alt="Document Icon" className="document-icon" />
            </a>
          </p>
        )}
      </div>
    </div>
  );
}


